import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Grid, Button, Breadcrumbs, Typography, Link } from '@material-ui/core';

import { fetchQuota, patchQuota, getBrands } from 'store/actions';
import { Profile, AccountDetails, Export, QuotaToolbar, QuotaTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  }
}));

const defaultState = {
  options: {
    pageIndex: 1,
    pageSize: 10,
    search: '',
    brand: ''
  },
  totalCount: 0,
  items: []
}

const Quota = props => {

  const classes = useStyles();
  const { fetchQuota, patchQuota, } = props;
  const { user, brands, items, totalCount } = props;
  const { userId } = useParams()

  const [options, setOptions] = useState(defaultState.options)

  const handlePageChange = ({page = 0, pageSize=options.pageSize}) => {
    setOptions({
      ...options,
      pageIndex: page + 1,
      pageSize
    })
  }

  const handleInputChange = ev => {
    setOptions({
      ...options,
      pageIndex: 1,
      [ev.target.name]: ev.target.value
    })
  }

  const handleUpdate = (options) => {
    patchQuota(user.id, options)
  }

  useEffect(() => {
    fetchQuota(userId, options)
    getBrands()
  }, [fetchQuota, userId, options])

  let _user = {
    email: user.email,
    name: user.userInfo ? `${user.userInfo.firstname} ${user.userInfo.lastname}` : user.userName,
    avatar: user.userInfo ? user.userInfo.avatar : '',
    location: user.userInfo ? `${user.userInfo.state},${user.userInfo.country}` : '',
    lastLoginTime: user.lastLoginTime,
    exchangeRate: user.exchangeRate || 6.5,
    marginRate: user.marginRate
  }

  return (
    <div
      className={classes.root}
    >
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" href="/">
          首页
        </Link>
        <Link color="inherit" href="/users">
          用户列表
        </Link>
        <Typography color="textPrimary">报价单</Typography>
      </Breadcrumbs>
      <div className={classes.content}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <Profile user={_user} />
            <Export className={classes.marginTop} />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xl={8}
            xs={12}
          >
            <AccountDetails />
          </Grid>
        </Grid>
        <QuotaToolbar
          brand={options.brand}
          search={options.search}
          brands={brands}
          handleChange={handleInputChange}
        />
        <QuotaTable
          className={classes.marginTop}
          exchangeRate={user.exchangeRate}
          currencyCode={user.currencyCode}
          items={items}
          totalCount={totalCount}
          handleUpdate={handleUpdate}
          handleChange={handlePageChange}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  user: state.quota.user,
  loading: state.quota.loadingQuota,
  patching: state.quota.patching,
  totalCount: state.quota.totalCount,
  items: state.quota.items,
  brands: state.brands.brands
})

const mapDispatchToProps = dispatch => ({
  fetchQuota: (userId, options) => dispatch(fetchQuota(userId, options)),
  patchQuota: (userId, options) => dispatch(patchQuota(userId, options)),
  getBrands: dispatch(getBrands())
})

const connectedQuota = connect(mapStateToProps, mapDispatchToProps)(Quota)

export default connectedQuota