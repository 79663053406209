import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';
import { Select } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
}));

const QuotaToolbar = props => {
  const { className, brands, brand, search, handleChange, ...rest } = props;

  const classes = useStyles();

  const [_search, setValue] = useState(search || '')

  const handleInputChange = ev => {
    setValue(ev.target.value)
  }

  const handleKeyUp = ev => {
    if (ev.key === 'Enter') {
      handleChange({ target: { name: 'search', value: ev.target.value } })
    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Select
          options={brands}
          name="brand"
          defaultValue="请选择品牌"
          value={brand}
          onChange={handleChange}
        />
        <SearchInput
          name="search"
          value={_search}
          className={classes.searchInput}
          placeholder="搜索品名,型号..."
          onChange={handleInputChange}
          onKeyUp={handleKeyUp}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={ev => handleChange({ target: { name: 'search', value: _search } })}
        >
          搜索
        </Button>
      </div>
    </div>
  );
};

QuotaToolbar.propTypes = {
  className: PropTypes.string,
  brands: PropTypes.array,
  brand: PropTypes.string,
  search: PropTypes.string,
  handleChange: PropTypes.func
};

export default QuotaToolbar;
