import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TextField
} from '@material-ui/core';

import { fetchQuota } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fixedLayout: {
    tableLayout: 'fixed'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  cellContent: {
    width: '100%'
  },
}));

const defaultState = {
  option: {
    search: '',
    pageIndex: 1,
    pageSize: 10
  }
}

const QuotaTable = props => {

  const { className, items, newItems, totalCount, quotaSearch, userId, } = props;
  const { fetchQuota, addItems } = props

  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [option, setOption] = useState(defaultState.option)

  useEffect(() => {
    let _option = Object.assign({}, option, { search: quotaSearch })
    userId && fetchQuota(userId, _option)
  }, [userId, option, quotaSearch, fetchQuota])

  const handleSelectAll = event => {
    const { items } = props;

    let selectedRows;

    if (event.target.checked) {
      selectedRows = items.map(item => item.id);
    } else {
      selectedRows = [];
    }

    setSelectedRows(selectedRows);
    addItems([...items])
  };

  const handleSelectOne = (event, item) => {
    const selectedIndex = selectedRows.indexOf(item.id);
    const _index = newItems.findIndex(({id}) => id === item.id)
    let newSelectedRows = [];
    let newSelectedItems = []

    if (_index === -1) {
      newSelectedItems = newSelectedItems.concat(newItems, {...item});
    } else if (_index === 0) {
      newSelectedItems = newSelectedItems.concat(newItems.slice(1));
    } else if (_index === newItems.length - 1) {
      newSelectedItems = newSelectedItems.concat(newItems.slice(0, -1));
    } else if (_index > 0) {
      newSelectedItems = newSelectedItems.concat(
        newItems.slice(0, _index),
        newItems.slice(_index + 1)
      );
    }

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, item.id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    addItems(newSelectedItems);
    setSelectedRows(newSelectedRows);
  };

  const getSelectedItem = id => (newItems.find(item => item.id === id) || {})

  const handlePageChange = (event, page) => {
    setPage(page);
    setOption({
      ...option,
      pageIndex: page + 1
    })
  };

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    setRowsPerPage(pageSize);
    setOption({
      ...option,
      pageSize
    })
  };

  const handleQtyChange = (ev, id) => {
    let index = newItems.findIndex(item => item.id === id)
    let item = {...newItems[index]}
    item.qty = ev.target.value
    newItems.splice(index, 1, item)
    addItems([...newItems])
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Table className={classes.fixedLayout}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={selectedRows.length === items.length}
                color="primary"
                indeterminate={
                  selectedRows.length > 0 &&
                  selectedRows.length < items.length
                }
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>货品编码</TableCell>
            <TableCell>产品</TableCell>
            <TableCell>规格</TableCell>
            <TableCell>成本价</TableCell>
            <TableCell>销售价</TableCell>
            <TableCell>数量</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.slice(0, rowsPerPage).map(item => (
            <TableRow
              className={classes.tableRow}
              hover
              key={item.id}
              selected={selectedRows.indexOf(item.id) !== -1}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedRows.indexOf(item.id) !== -1}
                  color="primary"
                  onChange={event => handleSelectOne(event, item)}
                  value="true"
                />
              </TableCell>
              <TableCell>{item.skuId}</TableCell>
              <TableCell>
                <div className={classes.nameContainer}>
                  <Avatar
                    className={classes.avatar}
                    src={item.img + '?imageView2/1/w/40/h/40/ignore-error/1'}
                  />
                  <Typography variant="body1">{item.cnName}</Typography>
                </div>
              </TableCell>
              <TableCell>{item.cnSpec}</TableCell>
              <TableCell>￥{item.costPrice}</TableCell>
              <TableCell>
                <div className={classes.cellContent}>
                  ${item.salesPrice}
                </div>
              </TableCell>
              <TableCell>
                {
                  selectedRows.indexOf(item.id) !== -1
                    ? (<TextField
                        placeholder="数量"
                        name="qty"
                        onChange={ev => handleQtyChange(ev, item.id)}
                        required
                        value={getSelectedItem(item.id).qty || ''}
                        className={classes.input}
                      />) : null
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 50, 100]}
      />
    </div>
  );
};

QuotaTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  loadingQuota: state.quota.loadingQuota,
  quotaSearch: state.orders.quotaSearch,
  items: state.quota.items,
  totalCount: state.quota.totalCount,
})

const mapDispatchToProps = dispatch => ({
  fetchQuota: (userId, options) => dispatch(fetchQuota(userId, options)),
})

const connectedQuotaTable = connect(mapStateToProps, mapDispatchToProps)(QuotaTable)

export default connectedQuotaTable
