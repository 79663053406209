import * as React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { TableRow, TableCell, TableFooter } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#333',
    fontWeight: 'bolder',
    fontSize: 13,
    '& td': {
      color: 'inherit',
      fontWeight: 'inherit'
    }
  },
}));

function Footer (props) {

  const classes = useStyles()
  const { orders, currencyList } = props
  const { currencyCode = 'USD' } = (orders[0] || {}).user || {}
  const { code: currencyMark } = currencyList.find(cur => cur.value === currencyCode) || {}
  const amountSum = orders.reduce((cur, next) => {
    let { subtotal, discount, freight, serviceCharge, otherFee } = next
    return cur + subtotal * (1 - discount) + parseFloat(freight) + parseFloat(serviceCharge) + parseFloat(otherFee)
  }, 0)

  const paymentSum = orders.reduce((cur, next) => {
    return cur + parseFloat(next.receivedPayment)
  }, 0)

  return (
    <TableFooter className={classes.root}>
      <TableRow>
        <TableCell colSpan={5}>本页合计</TableCell>
        <TableCell>{currencyMark + amountSum.toFixed(2)}</TableCell>
        <TableCell>{currencyMark + paymentSum.toFixed(2)}</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableFooter>
  )
}

const mapStateToProps = state => ({
  currencyList: state.global.currencyList
})

const connectedFooter = connect(mapStateToProps)(Footer)

export default connectedFooter;
