import * as React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import moment from 'moment'
import clsx from 'clsx'
import { statusList } from '../../../status'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#f5f5f5',
      '& th': {
        color: '#aaa',
        fontWeight: 300,
        textAlign: 'left',
        border: '1px solid #e5e5e5',
        borderWidth: '1px 0 0 0',
        padding: '11px 0 11px 10px',
        '&:first-child': {
          borderLeftWidth: 1
        },
        '&:last-child': {
          borderRightWidth: 1
        },
      },
    },
    flex: {
      display: 'flex'
    },
    mainCell: {
      width: '70%',
      fontSize: 13,
    },
    cell: {
      width: '10%',
      fontSize: 13,
    },
    flexGrow4: {
      flex: 4
    },
    flexGrow3: {
      flex: 3
    },
    flexGrow2: {
      flex: 2
    },
    flexGrow1: {
      flex: 1
    },
    stressText: {
      color: '#333',
      paddingLeft: 4
    },
    padding: {
      paddingRight: 20,
    },
    highlight: {
      fontWeight: 700,
      color: theme.palette.secondary.main
    },
  }),
)
export default function TableFooter (props) {

  const classes = useStyles()
  const { order } = props
  const { label: status } = statusList.find(s => s.value === order.trackStatus) || {}

  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell colSpan={7}>
          <div className={classes.flex}>
            <div className={clsx(classes.stressText, classes.padding)}>
              {moment(order.createdAt).format('YY/MM/DD hh:mm')}
            </div>
            <div className={classes.padding}>订单号: <span className={classes.stressText}>{order._orderId}</span></div>
            <div className={classes.padding}>用户名: <span className={classes.stressText}>{order.user && order.user.userName}</span></div>
            <div className={classes.padding}>订单状态: <span className={classes.stressText}>{status}</span></div>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.mainCell} colSpan={4}>
          <div className={classes.flex}>
            <div className={classes.flexGrow3}>品名</div>
            <div className={classes.flexGrow1}>数量</div>
            <div className={classes.flexGrow1}>价格</div>
            <div className={classes.flexGrow1}>订单金额</div>
            <div className={classes.flexGrow1}>备注</div>
          </div>
        </TableCell>
        <TableCell className={classes.cell}>总数量</TableCell>
        <TableCell className={classes.cell}>总金额</TableCell>
        <TableCell className={classes.cell}>订单留言</TableCell>
      </TableRow>
    </TableHead>
  )
}
