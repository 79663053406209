import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import browserHistory from 'common/history';
import {
  Breadcrumbs,
  Typography,
  Button
} from '@material-ui/core';

import {
  Address,
  Info,
  OrderTable,
  QuotaDialog,
  QuotaTable,
  statusClass,
  OrderAmount,
} from './components'

import { getUser, confirm, alert } from 'store/actions'
import { userService, ordersService } from 'store/services'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    fontSize: 13
  },
  content: {
    marginTop: theme.spacing(3),
  },
  inner: {
    width: '100%',
  },
  table: {
    width: '100%',
    borderTop: '1px solid #e5e5e5',
  },
  fixedLayout: {
    tableLayout: 'fixed'
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1)
  }
}));

const defaultOrder = {
  address: {},
  user: {},
  items: []
}

function transform (item) {
  return {
    GUID: item.GUID,
    modelName: item.modelName,
    qty: item.qty,
    costPrice: item.costPrice,
    salesPrice: item.salesPrice,
    img: item.img,
    skuId: item.skuId,
    spec: item.spec,
    size: item.size,
    weight: item.weight
  }
}

function calculateOrder (items) {

  let qty = 0;
  let subtotal = 0;
  let productsCost = 0;
  let volumn = 0;
  let weight = 0;

  items.forEach(item => {
    qty += parseInt(item.qty);
    subtotal += item.qty * item.salesPrice;
    productsCost += item.qty * item.costPrice;

    let unitWeight =  parseFloat(item.weight) || 0;
    let unitVolumn = item.size.split(/[^\d]/).reduce((cur: number, next: string) => cur + parseFloat(next) || 1, 0);

    weight = weight + item.qty * unitWeight;
    volumn = volumn + item.qty * unitVolumn;
  })

  subtotal = parseFloat(subtotal.toFixed(2))
  productsCost = parseFloat(productsCost.toFixed(2))

  return {
    qty,
    subtotal,
    productsCost,
    weight,
    volumn
  }
}

function CreateOrder (props) {

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [order, setOrder] = useState(defaultOrder)
  const [newItems, setNewItems] = useState([])
  const [loading, setLoading] = useState(false)
  const { confirm, alert } = props
  const { userId } = useParams()
  // const disabled = order.trackStatus !== statusClass.WAIT_SALLER_CONFIRM

  const addItemsToOrder = items => {

    let orderItemIds = order.items.map(item => item.skuId)
    let validItems = []
    let invalidItemNames = []

    items.forEach(each => {
      if (orderItemIds.indexOf(each.skuId) < 0) {
        validItems.push(transform(each))
      } else {
        invalidItemNames.push(each.skuId)
      }
    })

    if (invalidItemNames.length) {
      let message = invalidItemNames.join('，')
      alert({ type: 'warn', message: `${message}在订单中已经存在,请直接修改数量!` })
    }
    

    let length = validItems.length
    if (length > 0) {

      let _items = validItems.concat(order.items)
      setOrder({
        ...order,
        ...calculateOrder(_items),
        items: _items,
      })
      setTimeout(() => {
        alert({ type: 'info', message: `成功添加了 ${length} 个产品, 请记得保存订单修改` })
      }, 2000)
    }
  }

  const getUser = userId => {
    userService.fetchUser(userId)
      .then(res => {
        if (res.status === 0) {
          const { addressList, userInfo, ...userProp } = res.data
          const defaultAddress = addressList.find(addr => addr.isDefault) || {}
          setOrder({
            ...order,
            address: defaultAddress,
            user: Object.assign(userProp, userInfo, { id: userProp.id }),
          })
        }
      })
  }
  
  const selectItems = items => {
    setNewItems(items)
  }

  const updateOrder = items => {
    setOrder({
      ...order,
      ...calculateOrder(items),
      items,
    })
  }

  useEffect(() => {
    getUser(userId)
  }, [userId])

  const handleCreate = ev => {
    confirm('确定创建该订单吗?')
      .then(() => {
        setLoading(true)
        return ordersService.createOrder(userId, Object.assign(order, {trackStatus: statusClass.WAIT_SALLER_CONFIRM}))
      })
      .then(res => {
        if (res.status === 0 && res.data.orderID) {
          setLoading(false)
          alert({ type: 'success', message: '订单创建成功.' })
          browserHistory.push(`/orders/${res.data.orderID}`)
        } else {
          return Promise.reject()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  console.log(order)

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          首页
        </Link>
        <Link color="inherit" to="/users">
          用户列表
        </Link>
        <Typography color="textPrimary">创建订单</Typography>
      </Breadcrumbs>
      <div className={classes.content}>
        <Address order={order}/>
        <Info order={order}/>
        <OrderTable
          userId={userId}
          order={order}
          updateOrder={updateOrder}
          showDialog={setOpen}
        />
        <OrderAmount order={order}/>
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreate}
          className={classes.button}
          disabled={loading}
        >创建订单</Button>
      </div>
      <QuotaDialog
        open={open}
        setOpen={setOpen}
        newItems={newItems}
        selectItems={selectItems}
        addItemsToOrder={addItemsToOrder}
      >
        <QuotaTable
          userId={order.user.id}
          newItems={newItems}
          addItems={selectItems}
        />
      </QuotaDialog>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  getUser: userId => dispatch(getUser(userId)),
  confirm: options => dispatch(confirm(options)),
  alert: ({type, message}) => dispatch(alert({type, message})),
})

const connectedOrder = connect(mapStateToProps, mapDispatchToProps)(CreateOrder)

export default connectedOrder
