import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Collapse,
  Button
} from '@material-ui/core'

import { updateProduct, handleUpload } from 'store/actions'
import { Item } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const sku = {
  img: '',
  spec: '',
  cnSpec: '',
  costPrice: '',
  remark: '',
  skuId: ''
}

const Skus = props => {

  const classes = useStyles();
  const { className, product, } = props
  const { updateProduct } = props

  // const handleUpload = ev => {
  //   const file = ev.target.files[0]
  //   uploadPackage(file)
  // }

  const handleAddSku = ev => {
    const { items } = product
    updateProduct({
      ...product,
      touchedItems: true,
      items: [{...sku}, ...items]
    })
  }

  const handleUploadSkuImg = (index, ev) => {
    let file = ev.target.files[0]
    handleUpload(file)
      .then(res => {
        if (res.status === 0) {
          let { items } = product
          items.splice(index, 1, Object.assign({}, items[index], {img: res.data.url }))
          updateProduct({
            ...product,
            touchedItems: true,
            items: [...items]
          })
        }
      })
  }

  const handleChange = (index, ev) => {
    let {items} = product
    items.splice(index, 1,
      Object.assign({},
        items[index],
        {
          [ev.target.name]:
          ev.target.type === 'checkbox'
            ? ev.target.checked
            : ev.target.value
        }
      )
    )
    product.outOfProduction = items.every(item => item.outOfProduction)
    updateProduct({
      ...product,
      touchedItems: true,
      items: [...items]
    })
  }

  const handleRemove = index => {
    let {items} = product
    items.splice(index, 1)
    updateProduct({
      ...product,
      touchedItems: true,
      items: [...items]
    })
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Card
        className={classes.content}
      >
        <CardHeader
          title={<Typography variant="h5" component="h2">SKU</Typography>}
        />
        <Divider/>
        <CardContent>
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={handleAddSku}
          >新增SKU</Button>
          <Collapse in={product.items.length > 0}>
            {
              product.items.map(
                (item, index) => (
                  <Item
                    key={index}
                    id={product.modelId + '-' + index}
                    item={item}
                    uploadSkuImg={ev => handleUploadSkuImg(index, ev)}
                    handleChange={ev => handleChange(index, ev)}
                    handleRemove={ev => handleRemove(index)}
                  />
                )
              )
            }
          </Collapse>
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = state => ({
  product: state.product.product,
  loading: state.product.uploadingPackage
})

const mapDispatchToProps = dispatch => ({
  updateProduct: product => dispatch(updateProduct(product)),
})

const connectedSkus = connect(mapStateToProps, mapDispatchToProps)(Skus)


connectedSkus.propTypes = {
  className: PropTypes.string,
  updateProduct: PropTypes.func
};

export default connectedSkus