import { productsConst } from '../constants';

const defaultState = {
  loading: false,
  uploadingPrimary: false,
  products: [],
  error: ''
}

export function products (state = defaultState, action) {
  switch (action.type) {
    // 获取产品列表
    case productsConst.GET_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case productsConst.GET_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        products: action.products,
        totalCount: action.totalCount
      });
    case productsConst.GET_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    case productsConst.DELETE_PRODUCT:
      let { products } = state
      let index = products.findIndex(product => product.id === action.id)
      let newProducts = []
      if (index > -1) {
        if (index === 0) {
          newProducts = newProducts.concat(products.slice(1))
        } else if (index === products.length - 1) {
          newProducts = newProducts.concat(products.slice(0, -1))
        } else {
          newProducts = newProducts.concat(products.slice(0, index), products.slice(index + 1))
        }
      }
      return {
        products: newProducts,
      }
    default:
      return state
  }
}