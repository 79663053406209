const statusList = [
  {
    label: '待买家提交',
    value: 201
  },
  {
    label: '待确认库存',
    value: 202
  },
  {
    label: '待付款',
    value: 203
  },
  {
    label: '待发货',
    value: 204
  },
  {
    label: '待收货',
    value: 205
  },
  {
    label: '已完成',
    value: 206
  },
]

const statusClass = {
  WAIT_BUYER_CONFIRM: 201,
  WAIT_SALLER_CONFIRM: 202,

  WAIT_PAY: 203,
  WAIT_DELIVERY: 204,
  WAIT_RECEIVE: 205,
  COMPLETE: 206,
  CLOSED:-999,
}

export {statusList, statusClass}