import { globalConst } from '../constants';
import { currencyList } from './currency'

const defaultState = {
  loading: false,
  ads: [],
  ad: {},
  tags: [],
  productsWithTags: [],
  totalCount: 0,
  messages: [],
  message: {},
  unreadMessageCount: 0,
  currencyList: currencyList
}

export function global (state = defaultState, action) {
  switch (action.type) {
    case globalConst.SHOW_LOADING:
      return Object.assign({}, state, {
        loading: true,
      })
    case globalConst.CLOSE_LOADING:
      return Object.assign({}, state, {
        loading: false,
      })

    // 广告
    case globalConst.GET_AD_LIST:
      return Object.assign({}, state, {
        ads: action.ads,
      })

    case globalConst.GET_AD:
      return Object.assign({}, state, {
        ad: action.ad,
      })
    // 消息
    case globalConst.GET_MESSAGE_LIST:
      return Object.assign({}, state, {
        totalCount: action.totalCount,
        messages: action.messages,
      })
    case globalConst.GET_MESSAGE:
      let message = state.messages.find(msg => msg.id === action.id) || {}
      return Object.assign({}, state, {
        message: message,
      })
    case globalConst.UPDATE_MESSAGE:
      return Object.assign({}, state, {
        message: Object.assign({}, state.message, action.message),
      })
    case globalConst.GET_UNREAD_MESSAGE_COUNT:
      return Object.assign({}, state, {
        unreadMessageCount: action.count,
      })
    case globalConst.GET_PRODUCTS_WITH_TAGS:
      return Object.assign({}, state, {
        loading: false,
        productsWithTags: action.products,
        totalCount: action.totalCount
      });
    default:
      return state
  }
}