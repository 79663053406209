import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Collapse
} from '@material-ui/core'

import { updateProduct, uploadPrimaryImages } from 'store/actions'
import { Upload, ImageInput } from '..'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const Images = props => {

  const classes = useStyles();
  const { className, product } = props
  const { updateProduct, uploadPrimaryImages } = props

  const handleUpload = ev => {
    const files = ev.target.files
    uploadPrimaryImages(files)
  }

  const handleChange = (index, url) => {
    const { images } = product
    let image = images[index]
    let newImage = Object.assign({}, image, { url })
    images.splice(index, 1, newImage)
    updateProduct({
      ...product,
      touchedImages: true,
      images: [...images]
    })
  }

  const handleRemove = index => {
    const { images } = product
    images.splice(index, 1)
    updateProduct({
      ...product,
      touchedImages: true,
      images: [...images]
    })
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Card
        className={classes.content}
      >
        <CardHeader
          title={<Typography variant="h5" component="h2">主图</Typography>}
        />
        <Divider/>
        <CardContent>
          <Upload label="上传主图" handleChange={handleUpload} id="primary-upload-button" />
          <Collapse in={product.images.length > 0}>
            {
              product.images
                ? product.images.map(
                  (image, index) => (
                    <ImageInput
                      key={index}
                      image={image}
                      handleChange={ev => handleChange(index, ev.target.url)}
                      handleRemove={ev => handleRemove(index)}
                    />
                  )
                ) : null
            }
          </Collapse>
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = state => ({
  product: state.product.product,
  uploadingPrimary: state.products.uploadingPrimary
})

const mapDispatchToProps = dispatch => ({
  updateProduct: product => dispatch(updateProduct(product)),
  uploadPrimaryImages: body => dispatch(uploadPrimaryImages(body))
})

const connectedImages = connect(mapStateToProps, mapDispatchToProps)(Images)


connectedImages.propTypes = {
  className: PropTypes.string,
  updateProduct: PropTypes.func
};

export default connectedImages