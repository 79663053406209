import { productsConst } from '../constants';
import { productsService } from '../services';
import { alert } from './';
import { uuid } from '../helpers';
import { deleteItem } from '.';
import browserHistory from 'common/history';

// 获取单个商品
export function fetchProduct (id) {

  return (dispatch, getState) => {

    const { auth, products } = getState()
    if (auth.loggingIn || products.loadingSingle) return

    if (!id) return dispatch(getDefaultProduct())

    dispatch(getRequest())
    dispatch(alert({type: 'info', message: '加载中， 请耐心等待..'}))

    productsService.fetchProduct (id)
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
          dispatch(alert({type: 'success', message: '产品加载成功..'}))
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(getFailure(err.message))
      })
  }

  function getRequest () {
    return {
      type: productsConst.GET_PRODUCT
    }
  }

  function getSuccess (product) {
    return {
      type: productsConst.GET_PRODUCT_SUCCESS,
      product,
    }
  }

  function getFailure (error) {
    return {
      type: productsConst.GET_PRODUCT_FAILURE,
      error
    }
  }

  function getDefaultProduct () {
    return {
      product: { _id: uuid(), },
      type: productsConst.GET_DEFAULT_PRODUCT,
    }
  }
}

// 新增产品
export function addProduct (product) {

  return dispatch => {

    dispatch(alert({type: 'info', message: '正在提交数据, 请勿操作..'}))

    if (product._id) {
      dispatch(deleteItem(product._id))
      delete product.id
    }
    return productsService.addProducts(product)
      .then(res => {
        if (res.status === 0) {
          dispatch(alert({type: 'success', message: '商品创建成功~'}))
          setTimeout(() => {
            browserHistory.push('/products')
          }, 2000)
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: `商品创建失败，错误原因: ${err.message}`}))
      })
  }
}

// 更新但不保存
export function updateProduct (product) {

  return dispatch => {

    dispatch(mutateProduct(product))
  }
}

// 获取标签
export function fetchTags () {

  return dispatch => {

    productsService.fetchTags()
      .then(res => {
        if (res.status === 0) {

          dispatch(success(res.data))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: `获取标签失败，错误原因: ${err.message}`}))
      })
  }

  function success (tags) {

    return {
      type: productsConst.GET_TAGS,
      tags
    }
  }
}

// 创建标签
export function saveTag (tag) {

  return dispatch => {

    productsService.saveTag(tag)
      .then(res => {
        if (res.status === 0) {

          dispatch(success(res.data))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: `创建标签失败，错误原因: ${err.message}`}))
      })
  }

  function success (tag) {

    return {
      type: productsConst.SAVE_TAG,
      tag
    }
  }
}

// 上传主图
export function uploadPrimaryImages (files) {

  return (dispatch, getState) => {

    let { product } = getState()
    product = product.product
    let preservedImages = [...product.images]
    let newImages = [...files].map(image => Object.assign({id: uuid(), loading: true, url: ''}))
    let images = product.images.concat(newImages)

    dispatch(mutateProduct({ ...product, images, touchedImages: true }))

    Promise.all([...files].map(handleUpload))
      .then(results => {
        let loadedImages = results.map((res, index) => {
          if (res.status === 0) {
            return Object.assign(res.data, { loading: false })
          } else {
            let { id } = newImages[index]
            return { id, success: false }
          }
        })
        dispatch(mutateProduct({ ...product, images: preservedImages.concat(loadedImages) }))
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: `上传失败，错误原因: ${err.message}`}))
        dispatch(uploadFailure(err.message))
      })
  }
}

// 上传包装图
export function uploadPackage (file) {

  return (dispatch, getState) => {

    let { product } = getState()
    product = product.product

    dispatch(uploading(true))
    dispatch(mutateProduct({ ...product, package: '' }))

    handleUpload(file)
      .then(res => {
        if (res.status === 0) {
          dispatch(uploading(false))
          dispatch(mutateProduct({ ...product, package: res.data.url }))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: `上传失败，错误原因: ${err.message}`}))
        dispatch(uploadFailure(err.message))
      })
  }

  function uploading (uploading) {
    return {
      type: productsConst.UPLOADING_PACKAGE,
      uploadingPackage: uploading
    }
  }
}

function uploadFailure (error) {
  return {
    type: productsConst.UPLOAD_FAILURE,
    error
  }
}

function mutateProduct (product) {
  return {
    type: productsConst.MUTATE_PRODUCT,
    product
  }
}

export function handleUpload (file) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('name', file.name)
  return productsService.upload(formData)
}