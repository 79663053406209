import { productsConst, globalConst } from '../constants';
import { productsService } from '../services';
import { alert, showLoading, closeLoading } from './'
import browserHistory from 'common/history'

// 获取商品列表
export function fetchProducts (options) {

  return (dispatch, getState) => {

    const { auth, products } = getState()
    if (auth.loggingIn || products.loading) return
    
    dispatch(getRequest())
    dispatch(alert({type: 'info', message: '产品列表加载中..'}))

    productsService.fetchProducts (options)
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
          dispatch(alert({type: 'success', message: '产品列表加载成功..'}))
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: `产品列表加载失败, 原因: ${err.message}`}))
        dispatch(getFailure(err.message))
      })
  }

  function getRequest () {
    return {
      type: productsConst.GET_REQUEST
    }
  }

  function getSuccess ({products, totalCount}) {
    return {
      type: productsConst.GET_SUCCESS,
      products,
      totalCount
    }
  }

  function getFailure (error) {
    return {
      type: productsConst.GET_FAILURE,
      error
    }
  }
}

// 根据标签获取商品列表
export function fetchProductsWithTags (options) {

  return (dispatch, getState) => {

    productsService.fetchProducts (options)
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: `产品列表加载失败, 原因: ${err.message}`}))
      })
  }

  function getSuccess ({products, totalCount}) {
    return {
      type: globalConst.GET_PRODUCTS_WITH_TAGS,
      products,
      totalCount
    }
  }
}

// 更新商品
export function patchProducts (products) {

  return (dispatch, getState) => {

    dispatch(showLoading())
    dispatch(patchRequest())

    return productsService.patchProducts (products)
      .then(res => {
        if (res.status === 0) {
          dispatch(patchSuccess())
          dispatch(closeLoading())
          dispatch(alert({ type: 'success', message: '产品更新成功.' }))
          setTimeout(() => {
            browserHistory.goBack()
          }, 2000)
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(closeLoading())
        dispatch(alert({ type: 'error', message: `产品更新失败，原因:${err.message}` }))
      })
  }

  function patchRequest () {
    return {
      type: productsConst.PATCH_REQUEST
    }
  }

  function patchSuccess () {
    return {
      type: productsConst.PATCH_SUCCESS,
    }
  }

  function patchFailure (error) {
    return {
      type: productsConst.PATCH_FAILURE,
      error
    }
  }
}

// 删除产品
export function deleteProduct (id) {

  return (dispatch, getState) => {

    productsService.deleteProduct(id)
      .then(res => {
        if (res.status === 0) {
          dispatch(alert({ type: 'success', message: '删除成功.' }))
          dispatch(deleteSuccess(id))
        }
      })

  }

  function deleteSuccess (id) {
    return {
      type: productsConst.DELETE_PRODUCT,
      id
    }
  }
}