import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardContent,
  Table,
} from '@material-ui/core';

import { TableFooter, TableHeader, ProductTable } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    fontSize: 13
  },
  content: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  inner: {
    width: '100%',
  },
  table: {
    width: '100%',
    borderTop: '1px solid #e5e5e5',
  },
  fixedLayout: {
    tableLayout: 'fixed'
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export default function OrderList (props) {

  const classes = useStyles()
  const { orders } = props

  return (
    <>
      {
        orders.map(order => (
          <Card className={classes.root} key={order.id}>
            <CardContent className={classes.content}>
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table className={clsx(classes.fixedLayout, classes.table)}>
                    <TableHeader order={order}/>
                    <ProductTable order={order}/>
                    <TableFooter order={order}/>
                  </Table>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        ))
      }
    </>
  )

}
