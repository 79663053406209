import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { TablePagination, } from '@material-ui/core';
import { Toolbar, OrderTable, OrderList, Loading } from './components';
import { fetchOrders } from 'store/actions';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const defaultState = {
  options: {
    pageIndex: 1,
    pageSize: 10,
    rowsPerPage: 20,
    search: '',
    maxItems: 5
  }
}

function Comp (props) {
  const { display, orders, ...restProps } = props
  if (display === 'table') return <OrderTable orders={orders} {...restProps}/>
  return <OrderList orders={orders}/>
}

function Orders (props) {

  const classes = useStyles()
  const { orders, totalCount, loading } = props
  const { fetchOrders } = props

  const [options, setOptions] = useState(defaultState.options)
  const { userId = '' } = useParams()
  const [display, setDisplay] = useState('table')
  const [page, setPage] = useState(0);

  options.userId = userId

  const handleChange = ({name, value}) => {
    setOptions({
      ...options,
      [name]: value
    })
  }

  const reFetchOrders = () => {
    fetchOrders(options)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    setOptions({
      ...options,
      pageIndex: page + 1
    })
  };

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    setPage(0)
    setOptions({
      ...options,
      pageIndex: 1,
      pageSize
    })
  };

  useEffect(() => {
    fetchOrders(options)
  }, [fetchOrders, options])

  return (
    <div className={classes.root}>
      <Toolbar
        display={display}
        setDisplay={setDisplay}
        handleChange={handleChange}
      />
      {
        loading
          ? <Loading/>
          : <Comp display={display} orders={orders} reFetchOrders={reFetchOrders}/>
      }
      <TablePagination
        component="div"
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={options.rowsPerPage}
        rowsPerPageOptions={[20, 50, 100]}
      />
    </div>
  )

}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  orders: state.orders.orders,
  totalCount: state.orders.totalCount,
})

const mapDispatchToProps = dispatch => ({
  fetchOrders: options => dispatch(fetchOrders(options)),
})

const connectedOrders = connect(mapStateToProps, mapDispatchToProps)(Orders)

export default connectedOrders