import * as React from 'react';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, Checkbox, } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { statusList } from '../../../status';

const useStyles = makeStyles(theme => ({
  statusWaitSeller: {
    color: '#ff6000'
  },
  statusComplete: {
    color: '#07f'
  }
}));


function OrderRow (props) {

  const classes = useStyles()
  const { checked, order, currencyList, handleChecked } = props
  const { currencyCode = 'USD' } = order.user || {}
  const { code: currencyMark } = currencyList.find(cur => cur.value === currencyCode) || {}
  const amount = order.subtotal * (1 - order.discount) + parseFloat(order.freight) + parseFloat(order.serviceCharge) + parseFloat(order.otherFee)
  const { label: status } = statusList.find(s => s.value === order.trackStatus) || {}

  return (
    <TableRow key={order.id}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={checked}
          color="primary"
          onChange={event => handleChecked(order.id)}
          value="true"
        />
      </TableCell>
      <TableCell>
        <Link to={`/orders/${order._orderId}`}>{order._orderId}</Link>
      </TableCell>
      <TableCell>{order.user.userName}</TableCell>
      <TableCell
        className={clsx({
          [classes.statusWaitSeller]: order.trackStatus === 202 || order.trackStatus === 204,
          [classes.statusComplete]: order.trackStatus === 205
        })}
      >{status}</TableCell>
      <TableCell>{order.paymentMethod}</TableCell>
      <TableCell>{order.deliveryMethod}</TableCell>
      <TableCell>{currencyMark + amount.toFixed(2)}</TableCell>
      <TableCell>{currencyMark + order.receivedPayment.toFixed(2)}</TableCell>
      <TableCell>{moment(order.createdAt).format('YY/MM/DD hh:mm')}</TableCell>
    </TableRow>
  )
}

const mapStateToProps = state => ({
  currencyList: state.global.currencyList
})

const connectedOrderRow = connect(mapStateToProps)(OrderRow)

export default connectedOrderRow;
