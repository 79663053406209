import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { SearchInput } from 'components'
import { searchQuota, alert } from 'store/actions'

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: theme.zIndex.snackbar - 1
  },
  appBar: {
    position: 'relative',
  },
  main: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flex: 1,
    height: 36,
    alignItems: 'center'
  },
  title: {
    marginRight: theme.spacing(2),
    color: '#fff'
  },
  search: {
    height: '100%'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function QuotaDialog (props) {
  const classes = useStyles();
  const { children, open, newItems } = props
  const { alert, setOpen, searchQuota, addItemsToOrder, selectItems } = props
  const [value, setValue] = useState('')

  const handleChange = ev => {
    setValue(ev.target.value)
  }

  const fireSearch = ev => {
    if (ev.key === 'Enter') {
      searchQuota(value)
    }
  }

  const handleSave = ev => {
    let itemsWithNoQty = []
    newItems.forEach(item => {
      if (isNaN(parseInt(item.qty))) {
        itemsWithNoQty.push(item.skuId)
      }
    })
    if (itemsWithNoQty.length) {
      return alert({type: 'error', message: `${itemsWithNoQty.join('，')}的数量必须提供！`})
    }
    addItemsToOrder(newItems)
    setOpen(false)
    selectItems([])
  }

  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      open={open}
      onClose={ev => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={ev => setOpen(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <div className={classes.main}>
            <Typography variant="h6" className={classes.title}>
              报价单
            </Typography>
            <SearchInput
              name="search"
              value={value}
              className={classes.search}
              placeholder="搜索品名,型号...，敲回车查询"
              onChange={handleChange}
              onKeyPress={fireSearch}
            />
          </div>
          <Button autoFocus color="inherit" onClick={handleSave}>
            保存
          </Button>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  searchQuota: search => dispatch(searchQuota(search)),
  alert: ({type, message}) => dispatch(alert({type, message})),
})

const connectedQuotaDialog = connect(mapStateToProps, mapDispatchToProps)(QuotaDialog)

export default connectedQuotaDialog
