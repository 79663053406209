import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';

import { fetchTags, saveTag, updateProduct } from 'store/actions'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  row: {
    padding: '10px 0'
  },
  tagList: {
    padding: '20px 0'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(1),
  }
}));

const DialogTitle = (props) => {

  const { children, onClose, ...other } = props;
  const classes = useStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function Tags (props) {

  const { tags, product, fetchTags, saveTag, updateProduct, } = props
  const classes = useStyles()

  const [open, setOpen] = useState(false);
  const [selected, setSeleted] = useState([]);
  const [tag, setTag] = useState(null)

  const handleClickOpen = () => {
    setOpen(true);
    const productTagIds = product.tags ? product.tags.map(tag => tag.id) : []
    productTagIds.length && setSeleted(productTagIds)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTag = ev => {
    let value = ev.target && ev.target.value || ''
    setTag({ label: value })
  }

  const handleSaveTag = ev => {
    if (tag && tag.label.length) {
      saveTag(tag)
      setTag(null)
    }
  }

  const handleSave = ev => {
    const selectedTags = tags.filter(tag => selected.findIndex(id => tag.id === id) > -1)
    updateProduct({
      ...product,
      tags: selectedTags
    })
    setOpen(false);
  }

  useEffect(() => {
    fetchTags()
  }, [fetchTags])

  const handleChange = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelectedRows = newSelectedRows.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSeleted(newSelectedRows);
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component="span"
        size="small"
        onClick={handleClickOpen}
      >添加产品标签</Button>

      <Dialog onClose={handleClose} fullWidth maxWidth="sm" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          勾选你想要添加的标签，然后保存
        </DialogTitle>
        <DialogContent dividers>
          <FormGroup row className={classes.tagList}>
            {
              tags.length ? tags.map(tag => (
                <FormControlLabel
                  key={tag.id}
                  control={
                    <Checkbox
                      checked={selected.indexOf(tag.id) > -1}
                      onChange={ev => handleChange(ev, tag.id)}
                    />
                  }
                  label={tag.label}
                />
              )) : <Typography variant="subtitle2">暂无标签，请先创建</Typography>
            }
          </FormGroup>
          {
            tag ? (
              <div className={classes.row}>
                <TextField value={tag.label} onChange={handleAddTag}/>
                <div className={classes.buttons}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleSaveTag}
                  >
                    保存
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    onClick={ev => setTag(null)}
                  >
                    取消
                  </Button>
                </div>
              </div> 
            ) : (<Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={handleAddTag}
                >
                  创建标签
                </Button>)
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => ({
  product: state.product.product,
  tags: state.product.tags,
})

const mapDispatchToProps = dispatch => ({
  fetchTags: () => dispatch(fetchTags()),
  saveTag: tag => dispatch(saveTag(tag)),
  updateProduct: product => dispatch(updateProduct(product)),
})

const connectedTags = connect(mapStateToProps, mapDispatchToProps)(Tags)

export default connectedTags
