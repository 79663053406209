import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  InputBase,
  TablePagination,
  Select,
  MenuItem,
  Collapse,
} from '@material-ui/core';

import { fetchProducts, fetchBrands, patchProducts, deleteProduct, confirm } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1200
  },
  nameContainer: {
    minWidth: 220,
    display: 'flex',
    alignItems: 'center'
  },
  fixedLayout: {
    tableLayout: 'fixed'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cellContent: {
    width: '100%'
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  warnButton: {
    color: 'rgb(220, 0, 78)'
  },
  alertButton: {
    color: 'red'
  },
  inputWidth: {
    width: 92,
  },
  input: {
    width: 92,
    paddingLeft: 6,
    border: '1px solid rgba(0, 0, 0, 0.23);'
  },
  hiddenButton: {
    paddingBottom: theme.spacing(1)
  }
}));

const defaultState = {
  editingRow: {
    id: 0,
    costPrice: '',
    brand: '',
    weight: '',
    size: ''
  }
}

const ProductsTable = props => {
  
  const classes = useStyles();
  const { className, products, brands, totalCount = 10, } = props;
  const { deleteProduct, handleChange, patchProducts, confirm } = props

  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [editingRow, setEditingRow] = useState(defaultState.editingRow)
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { products } = props;

    let selectedRows;

    if (event.target.checked) {
      selectedRows = products.map(item => item.id);
    } else {
      selectedRows = [];
    }

    setSelectedRows(selectedRows);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelectedRows);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    handleChange({page: page + 1})
  };

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    setRowsPerPage(pageSize);
    handleChange({pageSize})
  };

  const handleEdit = ev => {
    setEditingRow({
      ...editingRow,
      [ev.target.name]: ev.target.value
    })
  }

  // const edit = payload => {
  //   if (!payload) return setEditingRow(defaultState.editingRow)
  //   setEditingRow({
  //     ...editingRow,
  //     ...payload
  //   })
  // }

  // const save = () => {
  //   if (editingRow.id) {
  //     patchProducts(editingRow)
  //     setEditingRow({})
  //   }
  // }

  const offShelf = ev => {

    if (selectedRows.length > 0) {
      let _products = selectedRows.map(id => Object.assign({}, products.find(product => product.id === id), { outOfProduction: true }))
      confirm('下架产品会在前台显示Out of Production, 确定下架吗？')
        .then(() => {
          patchProducts(_products)
          setSelectedRows([])
        })
    }
  }

  const handleDelete = (id, name) => {
    confirm(`确定删除【${name}】这个产品吗？`)
      .then(() => {
        deleteProduct(id)
      })
  }

  useEffect(() => {
    fetchBrands()
    fetchProducts()
  })

  return (
    <div className={clsx(classes.root, className)}>
      <Collapse in={selectedRows.length > 0} className={classes.hiddenButton}>
        <Button
          color="primary"
          variant="contained"
          className={classes.spacingLeft}
          onClick={offShelf}
        >
          停产选中产品
        </Button>
      </Collapse>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.length === products.length}
                        color="primary"
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < products.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>产品</TableCell>
                    <TableCell>品牌</TableCell>
                    <TableCell>重量</TableCell>
                    <TableCell>尺寸</TableCell>
                    <TableCell>装箱量</TableCell>
                    <TableCell>分类</TableCell>
                    <TableCell>
                      <Table className={classes.fixedLayout}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sku图片</TableCell>
                            <TableCell>SkuId</TableCell>
                            <TableCell>Colors</TableCell>
                            <TableCell>进货价</TableCell>
                            <TableCell>停产</TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableCell>
                    <TableCell>
                      <Button disableElevation disableRipple>编辑</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map(product => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={product.id}
                      selected={selectedRows.indexOf(product.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.indexOf(product.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, product.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                            src={product.coverImage + '?imageView2/1/w/40/h/40/ignore-error/1'}
                          />
                          <Typography variant="body1">{product.cnName}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>
                        {
                          editingRow.id === product.id
                            ? (<Select
                                inputProps={{name: 'brand'}}
                                value={editingRow.brand}
                                onChange={handleEdit}
                                className={classes.inputWidth}
                              >
                                {
                                  brands.map(brand => <MenuItem value={brand.value} key={brand.value}>{brand.label}</MenuItem>)
                                }
                              </Select>)
                            : product.brand
                        }
                      </TableCell>
                      <TableCell>
                        {
                          editingRow.id === product.id
                          ? <InputBase
                              className={clsx(classes.inputWidth, classes.input)}
                              name="weight"
                              value={editingRow.weight}
                              onChange={handleEdit}
                            />
                          : product.weight
                        }g
                      </TableCell>
                      <TableCell>
                        {
                          editingRow.id === product.id
                          ? <InputBase
                              className={clsx(classes.inputWidth, classes.input)}
                              name="size"
                              value={editingRow.size}
                              onChange={handleEdit}
                            />
                          : product.size
                        }cm
                      </TableCell>
                      <TableCell>
                        {
                          editingRow.id === product.id
                          ? <InputBase
                              className={clsx(classes.inputWidth, classes.input)}
                              name="qtyPerCtn"
                              value={editingRow.qtyPerCtn}
                              onChange={handleEdit}
                            />
                          : product.qtyPerCtn
                        }
                      </TableCell>
                      <TableCell>{product.category}</TableCell>
                      <TableCell>
                        <Table className={classes.fixedLayout}>
                          <TableBody>
                            {
                              product.items.map(item => (
                                <TableRow key={item.skuId}>
                                  <TableCell>
                                    <Avatar
                                      className={classes.avatar}
                                      src={item.img + '?imageView2/1/w/40/h/40/ignore-error/1'}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {item.skuId}
                                  </TableCell>
                                  <TableCell>
                                    {item.spec}
                                  </TableCell>
                                  <TableCell>
                                    ￥{item.costPrice}
                                  </TableCell>
                                  <TableCell>
                                    {item.outOfProduction ? '停产' : null}
                                  </TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableCell>
                      <TableCell>
                        <Link to={`/products/${product.id}`}>
                          <Button color="secondary">编辑</Button>
                        </Link>
                        <Button color="default" onClick={ev => handleDelete(product.id, product.cnName)}>删除</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  // loading: state.products.loading,
  brands: state.brands.brands,
  products: state.products.products,
  totalCount: state.products.totalCount
})

const mapDispatchToProps = dispatch => ({
  confirm: message => dispatch(confirm(message)),
  deleteProduct: id => dispatch(deleteProduct(id)),
  patchProducts: products  => dispatch(patchProducts(products))
})

const connectedProductsTable = connect(mapStateToProps, mapDispatchToProps)(ProductsTable)

connectedProductsTable.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func
};

export default connectedProductsTable
