import React, { useState, useEffect, } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { TablePagination, } from '@material-ui/core';

import { UsersToolbar, UsersTable } from './components';
// import mockData from './data';
import { fetchUsers } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const defaultState = {
  options: {
    pageIndex: 1,
    pageSize: 10,
    rowsPerPage: 10,
    search: '',
    role: 'user'
  },
  totalCount: 0,
  users: []
}

const UserList = props => {
  const classes = useStyles();

  const { fetchUsers, users, totalCount } = props
  const [selectedUser, setSelectedUser] = useState([])
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState(defaultState.options)

  const handlePageChange = (event, page) => {
    setPage(page)
    setOptions({
      ...options,
      pageIndex: page + 1
    })
  };

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    setPage(0)
    setOptions({
      ...options,
      pageIndex: 1,
      pageSize
    })
  };

  const handleSearch = value => {
    setOptions({
      ...options,
      search: value
    })
  }

  const selectUser = users => {
    setSelectedUser(users)
  }

  useEffect(() => {
    fetchUsers(options)
  }, [fetchUsers, options])

  return (
    <div className={classes.root}>
      <UsersToolbar handleSearch={handleSearch} selectedUser={selectedUser}/>
      <div className={classes.content}>
        <UsersTable
          users={users}
          selectUser={selectUser}
        />
      </div>
      <TablePagination
        component="div"
        count={totalCount}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        page={page}
        rowsPerPage={options.rowsPerPage}
        rowsPerPageOptions={[10, 30, 50]}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  state: state,
  loading: state.users.loading,
  users: state.users.users,
  totalCount: state.users.totalCount
})

const mapDispatchToProps = dispatch => ({
  fetchUsers: form => dispatch(fetchUsers(form))
})

const connectedUserList = connect(mapStateToProps, mapDispatchToProps)(UserList)

export default connectedUserList;
