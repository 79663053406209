import { cacheConst } from '../constants';

const defaultState = {
  cachedProduct: null,
  caches: JSON.parse(localStorage.getItem(cacheConst.PRODUCT_CACHE_KEY) || '[]')
}

export function caches (state = defaultState, action) {
  switch (action.type) {
    case cacheConst.GET_CACHE_LIST:
      return Object.assign({}, state, {
        caches: defaultState.caches
      });

    case cacheConst.DELETE_CACHE_ITEM:
      let { caches } = state
      let index = caches.findIndex(cache => cache._id === action._id)
      if (index > -1) {
        caches.splice(index, 1)
        localStorage.setItem(cacheConst.PRODUCT_CACHE_KEY, JSON.stringify(caches))
      }
      return Object.assign({}, state, {
        caches: [...caches]
      });
    default:
      return state
  }
}