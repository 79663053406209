import * as React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { useStyles } from './style';

function ProductTable (props) {

  const classes = useStyles()
  const { order, currencyList } = props
  const { currencyCode = 'USD' } = order.user || {}
  const { code: currencyMark } = currencyList.find(cur => cur.value === currencyCode) || {}

  return (
    <TableBody className={classes.root}>
      {
        order.items.map((item, index) => (
          <TableRow key={item.id}>
            <TableCell className={classes.mainCell} colSpan={4}>
              <div className={classes.flex}>
                <div className={classes.flexGrow1}>
                  <img
                    src={item.img + '?imageView2/1/w/60/h/60/ignore-error/1'}
                    alt=""
                  />
                </div>
                <div className={clsx(classes.flex, classes.flexColumn, classes.justifyBetween, classes.flexGrow2)}>
                  <div>{item.name}</div>
                  <div>{item.spec}</div>
                </div>
                <div className={classes.flexGrow1}>× {item.qty}</div>
                <div className={classes.flexGrow1}>{currencyMark}{item.salesPrice.toFixed(2)}</div>
                <div className={classes.flexGrow1}>{currencyMark}{(item.qty * item.salesPrice).toFixed(2)}</div>
                <div className={classes.flexGrow1}>{item.remark || ''}</div>
              </div>
            </TableCell>
            {
              index === 0 ?
                (
                  <>
                    <TableCell
                      rowSpan={order.items.length}
                    >{order.qty}</TableCell>
                    <TableCell
                      rowSpan={order.items.length}
                    >{currencyMark}{order.subtotal}</TableCell>
                    <TableCell
                      rowSpan={order.items.length}
                    >{order.sellerRemark}</TableCell>
                  </>
                )
                : null
            }
          </TableRow>
        ))
      }
    </TableBody>
  )
}

const mapStateToProps = state => ({
  currencyList: state.global.currencyList
})

const connectedProductTable = connect(mapStateToProps)(ProductTable)

export default connectedProductTable;
