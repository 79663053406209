import request from '../../common/request'

export const ordersService = {
  fetchOrders,
  fetchOrder,
  confirmOrder,
  deleteOrder,
  createOrder
}

function fetchOrders (options) {
  return request.get({
    url: `/v2/cms/orders`,
    params: options
  })
}

function fetchOrder (id) {
  return request.get({
    url: `/v2/cms/orders/${id}`,
  })
}

function createOrder (userId, order) {
  return request.post({
    url: `/v2/cms/createOrder/${userId}`,
    body: order
  })
}

function confirmOrder (order) {
  return request.put({
    url: `/v2/cms/confirmOrder/${order._orderId}`,
    body: order
  })
}

function deleteOrder (orderIds) {
  return request.put({
    url: `/v2/cms/deleteOrders`,
    body: orderIds
  })
}