import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import moment from 'moment';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  FormControlLabel,
  Switch
} from '@material-ui/core';

import { fetchMessages } from 'store/actions'
import { SearchInput } from 'components'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    minWidth: 220,
    display: 'flex',
    alignItems: 'center'
  },
  fixedLayout: {
    // tableLayout: 'fixed'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  warnButton: {
    color: 'rgb(220, 0, 78)'
  },
  alertButton: {
    color: 'red'
  },
  inputWidth: {
    width: 92,
  },
  unread: {
    '& td': {
      color: '#333',
      fontWeight: 'bold'
    }
  },
  input: {
    width: 92,
    paddingLeft: 6,
    border: '1px solid rgba(0, 0, 0, 0.23);'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  toolbar: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  }
}));

const defaultState = {
  options: {
    search: '',
    pageIndex: 1,
    pageSize: 10,
  }
}

const MessagesTable = props => {
  
  const classes = useStyles();
  const { className, messages, totalCount, fetchMessages } = props;
  const [options, setOptions] = useState(defaultState.options)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')

  const handlePageChange = (event, page) => {
    setOptions({
      ...options,
      pageIndex: page
    });
  };

  const handleRowsPerPageChange = event => {
    setOptions({
      ...options,
      pageSize: event.target.value
    });
    setRowsPerPage(event.target.value)
  };

  const handleSearch = ev => {
    setOptions({
      ...options,
      search: search
    });
  }

  const handleSwitch = (ev, checked) => {
    setOptions({
      ...options,
      isReply: !checked
    });
  }

  useEffect(() => {
    if (options.isReply !== undefined) {
      options.isReply = options.isReply ? 1 : 0
    }
    fetchMessages(options)
  }, [fetchMessages, options])

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <div className={classes.toolbar}>
        <SearchInput
          value={search}
          placeholder="姓名，邮箱，主题"
          onChange={ev => setSearch(ev.target.value)}
        />
         <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleSearch}
          className={classes.button}
        >搜索</Button>
        <FormControlLabel
          control={<Switch size="small" checked={options.isReply === undefined ? false : !options.isReply} onChange={handleSwitch} />}
          label="只看未回"
        />
      </div>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.fixedLayout}>
                <TableHead>
                  <TableRow>
                    <TableCell>创建时间</TableCell>
                    <TableCell>姓名</TableCell>
                    <TableCell>邮箱</TableCell>
                    <TableCell>是否回复</TableCell>
                    <TableCell>主题</TableCell>
                    <TableCell>内容简介</TableCell>
                    <TableCell>
                      <Button disableElevation disableRipple>查看</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages.map(item => (
                    <TableRow
                      className={clsx({
                        [classes.tableRow]: true,
                        [classes.unread]: !item.hasRead
                      })}
                      hover
                      key={item.id}
                    >
                      <TableCell>{moment(item.createdAt).format('YY/MM/DD hh:mm A')}</TableCell>
                      <TableCell>{item.fullname}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>
                        { item.isReply ? '是' : '否' }
                      </TableCell>
                      <TableCell>
                        {item.subject}
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.ellipsis}>
                          {item.message}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Link to={`/messages/${item.id}`}>
                          <Button color="secondary">查看详情</Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={options.pageIndex - 1}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  totalCount: state.global.totalCount,
  messages: state.global.messages,
})

const mapDispatchToProps = dispatch => ({
  fetchMessages: options => dispatch(fetchMessages(options))
})

const connectedMessagesTable = connect(mapStateToProps, mapDispatchToProps)(MessagesTable)

connectedMessagesTable.propTypes = {
  className: PropTypes.string,
};

export default connectedMessagesTable
