import request from 'common/request'

export const adsService = {
  fetchAds,
  fetchAd,
  createAd,
  patchAd
}

function fetchAds () {
  return request.get({
    url: '/v2/adList',
  })
}

function fetchAd (id) {
  return request.get({
    url: `/v2/adList/${id}`,
  })
}

function createAd (body) {
  return request.post({
    url: '/v2/ad',
    body
  })
}

function patchAd (body) {
  return request.put({
    url: `/v2/ad/${body.id}`,
    body
  })
}