import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Paper,
  Button,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  TextField
} from '@material-ui/core';

import { fetchTags, fetchProductsWithTags, saveTag } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  buttons: {
    marginBottom: theme.spacing(2)
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  marginTop: {
    marginTop: theme.spacing(1)
  }
}));

const ProductsTable = props => {
  
  const classes = useStyles();
  const { className, tags, fetchTags, saveTag, fetchProductsWithTags } = props;

  const [selected, setSelected] = useState([])
  const [tag, setTag] = useState(null)

  const handleAddTag = ev => {
    let value = ev.target ? ev.target.value : ''
    setTag({ label: value })
  }

  const handleSaveTag = ev => {
    if (tag && tag.label.length) {
      saveTag(tag)
      setTag(null)
    }
  }

  const handleChange = (ev, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelectedRows = newSelectedRows.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelectedRows);
  }

  useEffect(() => {
    fetchTags()
  }, [fetchTags])

  useEffect(() => {
    fetchProductsWithTags({
      tags: selected.join('|')
    })
  }, [selected, fetchProductsWithTags])

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <div className={classes.buttons}>
        <Button variant="contained" color="primary" className={classes.marginRight} onClick={handleAddTag}>新建标签</Button>
        <Button variant="contained" color="secondary">删除选中标签</Button>
      </div>
      <Paper className={classes.content}>
        {
          tag ? (
            <div className={classes.row}>
              <TextField value={tag.label} onChange={handleAddTag}/>
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  className={clsx(classes.marginTop, classes.marginRight)}
                  onClick={handleSaveTag}
                >
                  保存
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.marginTop}
                  onClick={ev => setTag(null)}
                >
                  取消
                </Button>
              </div>
            </div> 
          ) : null
        }
        <FormGroup row className={classes.tagList}>
          {
            tags.length ? tags.map(tag => (
              <FormControlLabel
                key={tag.id}
                control={
                  <Checkbox
                    checked={selected.indexOf(tag.id) > -1}
                    onChange={ev => handleChange(ev, tag.id)}
                  />
                }
                label={tag.label}
              />
            )) : <Typography variant="subtitle2">暂无标签，请先创建</Typography>
          }
        </FormGroup>
      </Paper>
    </div>
  );
};

const mapStateToProps = state => ({
  // loading: state.products.loading,
  tags: state.product.tags,
})

const mapDispatchToProps = dispatch => ({
  fetchTags: () => dispatch(fetchTags()),
  fetchProductsWithTags: options => dispatch(fetchProductsWithTags(options)),
  saveTag: tag => dispatch(saveTag(tag)),
})

const connectedProductsTable = connect(mapStateToProps, mapDispatchToProps)(ProductsTable)

connectedProductsTable.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func
};

export default connectedProductsTable
