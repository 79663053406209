import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid,
  Avatar,
  TextField,
  IconButton,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'

// import { muateProduct, uploadPackage } from '../../../../store/actions'
import { Upload } from '../../../'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderTop: '1px solid #eee',
    position: 'relative'
  },
  row: {
    marginTop: theme.spacing(2)
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const Item = props => {

  const classes = useStyles();
  const { className, item, id } = props
  const { uploadSkuImg, handleChange, handleRemove } = props

  return (
    <div className={clsx(classes.root, className)}>
      <Upload id={id} label="上传图片" handleChange={uploadSkuImg}/>
      <Grid container spacing={2} className={classes.row}>
        <Grid item>
          <Avatar
            src={item.img + '?imageView2/1/w/50/h/50/ignore-error/1'}
            variant="square"
          />
        </Grid>
        <Grid item>
          <TextField
            label="中文规格"
            name="cnSpec"
            value={item.cnSpec}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <TextField
            label="英文规格"
            name="spec"
            value={item.spec}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <TextField
            label="出厂价"
            name="costPrice"
            value={item.costPrice}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <TextField
            label="备注"
            name="remark"
            value={item.remark || ''}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <TextField
            label="货品编码"
            name="skuId"
            value={item.skuId}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={item.outOfProduction} onChange={handleChange} name="outOfProduction" />}
            label="停产"
          />
        </Grid>
        <Grid item>
          <TextField
            label="图片链接"
            name="img"
            value={item.img || ''}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            size="small"
          />
        </Grid>
      </Grid>
      <IconButton className={classes.close} onClick={handleRemove}>
        <CloseRounded/>
      </IconButton>
    </div>
  )
}


Item.propTypes = {
  className: PropTypes.string,
  updateProduct: PropTypes.func
};

export default Item