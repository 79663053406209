import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx'
import {
  Typography,
  Grid
} from '@material-ui/core';

import { Container } from '..'
import { updateFinancial } from 'store/actions'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 12
  },
  flex: {
    display: 'flex',
  },
  center: {
    alignItems: 'center',
  },
  row: {
    '& > p': {
      marginRight: 10
    },
    marginBottom: 6
  },
  inputRow: {
    alignItems: 'flex-end'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  }
}));


function OrderAmount (props) {

  const classes = useStyles();
  const { order, currencyList } = props
  const { currencyCode = 'USD' } = order.user || {}
  const { code: currencyMark } = currencyList.find(cur => cur.value === currencyCode) || {}

  const discount = order.discount || 0
  const amount = order.subtotal * (1 - discount) + parseFloat(order.freight) + parseFloat(order.serviceCharge) + parseFloat(order.otherFee)

  return (
    <Container title="货款信息">
      <Grid container spacing={1} alignItems="stretch" className={classes.content}>
        <Grid item xs={6}>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">商品金额: </Typography>
            <Typography variant="subtitle2">{currencyMark}{order.subtotal}</Typography>
          </div>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">订单运费: </Typography>
            <Typography variant="subtitle2">{currencyMark}{order.freight}</Typography>
          </div>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">其他费用: </Typography>
            <Typography variant="subtitle2">{currencyMark}{order.otherFee}</Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">折扣金额: </Typography>
            <Typography variant="subtitle2">-{currencyMark}{order.subtotal * discount}</Typography>
          </div>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">合计应收: </Typography>
            <Typography variant="subtitle2">{currencyMark}{amount.toFixed(2)}</Typography>
          </div>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">实收金额: </Typography>
            <Typography variant="subtitle2">{currencyMark}{order.receivedPayment}</Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  currencyList: state.global.currencyList
})

const mapDispatchToProps = dispatch => ({
  updateFinancial: payload => dispatch(updateFinancial(payload)),
})

const connectedOrderAmount = connect(mapStateToProps, mapDispatchToProps)(OrderAmount)

export default connectedOrderAmount