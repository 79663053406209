import React from 'react';
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles';
import { CloseRounded } from '@material-ui/icons'

import {
  Avatar,
  TextField,
  IconButton
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  image: {
    width: 100,
    height: 100
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}));

export default function Toolbar(props) {

  const classes = useStyles()
  const { image, handleRemove } = props

  return (
    <div className={classes.root}>
      {
        image.url
          ? <Avatar
              className={classes.image}
              src={image.url + '?imageView2/1/w/100/h/100/ignore-error/1'}
              variant="square"
            />
          : <Skeleton
              className={classes.image}
              animation="wave"
              variant="rect"
            />
        
      }
      <TextField
        fullWidth
        label="图片地址"
        margin="dense"
        name="modelName"
        required
        size="small"
        value={image.url}
        variant="outlined"
      />
      <IconButton className={classes.close} onClick={handleRemove}>
        <CloseRounded/>
      </IconButton>
    </div>
  );
}