import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams, useLocation, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import {
  Typography,
  Breadcrumbs,
  Button,
} from '@material-ui/core'

import { fetchProduct, patchProducts, getBrands, saveCache, addProduct, getCacheItem } from 'store/actions'
import { BasicForm, Images, Package, Skus, Editor } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  buttons: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(2)
  }
}));

const Product = (props) => {

  const classes = useStyles();

  let { id } = useParams()
  let { search } = useLocation()
  const { history } = props;
  let from = null
  let query

  if (search) {
    query = new URLSearchParams(search)
    from = query.get('from')
  }

  const { fetchProduct, getBrands, patchProducts, addProduct, saveToCache, getCacheItem } = props
  const { product, brands } = props

  const handleSave = ev => {
    const handler = Number(id) ? patchProducts : addProduct
    handler(product)
  }

  const handleSaveCache = ev => {
    saveToCache(Object.assign({}, product, { saveTime: new Date() }))
    history.goBack();
  }

  useEffect(() => {
    from === 'drafts' ? getCacheItem(id) : fetchProduct(Number(id))
  }, [id, from, fetchProduct, getCacheItem])

  useEffect(() => {
    brands.length === 0 && getBrands()
  }, [getBrands, brands])

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          首页
        </Link>
        <Link color="inherit" to="/products">
          产品列表
        </Link>
          <Typography color="textPrimary">{id ? '编辑' : '新增'}产品</Typography>
      </Breadcrumbs>
      <BasicForm/>
      <Images/>
      <Package/>
      <Skus/>
      <Editor/>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="secondary"
          component="span"
          className={classes.button}
          onClick={handleSave}
        >
          保存
        </Button>
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={handleSaveCache}
        >
          保存草稿
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  product: state.product.product,
  brands: state.brands.brands,
})

const mapDispatchToProps = dispatch => ({
  fetchProduct: id => dispatch(fetchProduct(id)),
  getBrands: product => dispatch(getBrands()),
  patchProducts: product => dispatch(patchProducts(product)),
  saveToCache: product => dispatch(saveCache(product)),
  addProduct: product => dispatch(addProduct(product)),
  getCacheItem: id => dispatch(getCacheItem(id))
})

const connectedProduct = connect(mapStateToProps, mapDispatchToProps)(Product)

export default connectedProduct
