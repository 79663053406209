import React, { useState } from 'react'
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  Button,
  TextField
} from '@material-ui/core';

import { useStyles } from './style'
import { confirm, alert } from 'store/actions'

const defaultState = {
  editingRow: {
    id: 0,
    qty: 0,
    salesPrice: ''
  }
}

function ProductTable (props) {

  const classes = useStyles()
  const { order,currencyList, className, confirm, updateOrder } = props

  const { currencyCode = 'USD' } = order.user || {}
  const { code: currencyMark } = currencyList.find(cur => cur.value === currencyCode) || {}

  const [maxItems, setMaxItems] = useState(5)
  const [editingRow, setEditingRow] = useState(defaultState.editingRow)
  const showMore = maxItems < order.items.length

  const handleShowMore = ev => {
    let maxItems = showMore ? order.items.length : 5
    setMaxItems(maxItems)
  }

  const handleChange = ev => {
    setEditingRow({
      ...editingRow,
      [ev.target.name]: ev.target.value
    })
  }

  const handleEditRow = item => {
    if (item) {
      setEditingRow(Object.assign({}, editingRow, item))
    } else {
      setEditingRow({})
    }
  }

  const handleSave = ev => {
    const index = order.items.findIndex(item => item.GUID === editingRow.GUID)
    if (index > -1) {
      const mutatedItem = Object.assign({}, order.items[index], editingRow)
      order.items.splice(index, 1, mutatedItem)
      updateOrder(order.items)
      setEditingRow({})
    }
  }

  const handleRemove = item => {
    confirm(`确定删除“${item.name}-${item.spec}”吗`)
      .then(() => {
        removeItem(item.GUID)
      })
  }

  const removeItem = GUID => {
    let { items } = order
    let index = items.findIndex(item => item.GUID === GUID)
    let newItems = []
    if (index > -1) {
      if (index === 0) {
        newItems = newItems.concat(items.slice(1))
      } else if (index === items.length - 1) {
        newItems = newItems.concat(items.slice(0, -1))
      } else {
        newItems = newItems.concat(items.slice(0, index), items.slice(index + 1))
      }
      updateOrder(newItems)
    }
  }

  return (
    <Table className={clsx(className, classes.root)}>
      <TableHead className={classes.root}>
        <TableRow>
          <TableCell className={classes.miniCell}>序号</TableCell>
          <TableCell className={classes.mainCell} colSpan={5}>
            <div className={classes.flex}>
              <div className={classes.flexGrow3}>品名</div>
              <div className={classes.flexGrow1}>数量</div>
              <div className={classes.flexGrow1}>价格</div>
              <div className={classes.flexGrow1}>订单金额</div>
              <div className={classes.flexGrow1}>备注</div>
              <div className={classes.flexGrow1}>删除</div>
            </div>
          </TableCell>
          <TableCell className={classes.cell}>总数量</TableCell>
          <TableCell className={classes.cell}>总金额</TableCell>
          <TableCell className={classes.cell}>订单留言</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          order.items.slice(0, maxItems).map((item, index) => (
            <TableRow key={item.GUID}>
              <TableCell className={clsx(classes.alignItemsCenter, classes.miniCell)}>{index + 1}</TableCell>
              <TableCell className={classes.mainCell} colSpan={5}>
                <div className={classes.flex}>
                  <div className={classes.flexGrow1}>
                    <img
                      src={item.img + '?imageView2/1/w/60/h/60/ignore-error/1'}
                      alt=""
                    />
                  </div>
                  <div className={clsx(classes.flex, classes.flexColumn, classes.justifyBetween, classes.flexGrow2)}>
                    <div>{item.modelName}</div>
                    <div>{item.spec}</div>
                  </div>
                  <div className={clsx(classes.flex, classes.flexGrow1)}>
                    {
                      editingRow.GUID === item.GUID
                      ? (<TextField
                          placeholder="数量"
                          name="qty"
                          onChange={handleChange}
                          required
                          value={editingRow.qty}
                          className={classes.input}
                        />) : `× ${item.qty}`
                    }
                  </div>
                  <div className={classes.flexGrow1}>
                    {
                      editingRow.GUID === item.GUID
                      ? (<TextField
                          placeholder="价格"
                          name="salesPrice"
                          onChange={handleChange}
                          required
                          value={editingRow.salesPrice}
                          className={classes.input}
                        />) : `${currencyMark}${parseFloat(item.salesPrice).toFixed(2)}`
                    }
                  </div>
                  <div className={classes.flexGrow1}>{currencyMark}{(item.qty * item.salesPrice).toFixed(2)}</div>
                  <div className={classes.flexGrow1}>
                    {
                      editingRow.GUID === item.GUID
                      ? (<TextField
                          placeholder="备注"
                          name="remark"
                          onChange={handleChange}
                          required
                          value={editingRow.remark || ''}
                          className={classes.input}
                        />) : item.remark || ''
                    }
                  </div>
                  <div className={classes.flexGrow1}>
                    {
                      editingRow.GUID === item.GUID
                        ? (<>
                            <Button
                              className={classes.startEdge}
                              color="secondary"
                              size="small"
                              onClick={ev => handleSave(item)}
                            >
                              保存
                            </Button>
                            <Button
                              color="secondary"
                              size="small"
                              onClick={ev => handleEditRow()}
                            >
                              取消
                            </Button>
                          </>)
                        : (<>
                            <Button
                              className={classes.startEdge}
                              color="secondary"
                              size="small"
                              onClick={ev => handleEditRow(item)}
                            >
                              编辑
                            </Button>
                            <Button
                              color="secondary"
                              size="small"
                              onClick={ev => handleRemove(item)}
                            >
                              删除
                            </Button>
                          </>)
                    }
                  </div>
                </div>
              </TableCell>
              {
                index === 0 ?
                  (
                    <>
                      <TableCell
                        rowSpan={order.items.length}
                      >{order.qty}</TableCell>
                      <TableCell
                        rowSpan={order.items.length}
                      >{currencyMark}{order.subtotal}</TableCell>
                      <TableCell
                        rowSpan={order.items.length}
                      >{order.sellerRemark}</TableCell>
                    </>
                  )
                  : null
              }
            </TableRow>
          ))
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={8} className={classes.textCenter}>
            <Button color="primary" onClick={handleShowMore}>{showMore ? '显示全部>>' : '收起>>'}</Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

const mapStateToProps = state => ({
  currencyList: state.global.currencyList
})

const mapDispatchToProps = dispatch => ({
  confirm: message => dispatch(confirm(message)),
  alert: payload => dispatch(alert(payload))
})

const connectedProductTable = connect(mapStateToProps, mapDispatchToProps)(ProductTable)

export default connectedProductTable