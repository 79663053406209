const statusList = [
  {
    label: '全部订单',
    value: ''
  },
  {
    label: '待买家提交',
    value: 201
  },
  {
    label: '待确认库存',
    value: 202
  },
  {
    label: '待付款',
    value: 203
  },
  {
    label: '待发货',
    value: 204
  },
  {
    label: '待收货',
    value: 205
  },
  {
    label: '已完成',
    value: 206
  },
  {
    label: '订单关闭',
    value: -999
  },
]

export { statusList }