import React, { useState } from 'react';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { OrderRow, TableFooter, DeleteOrder } from './components';
import { confirm, alert, fetchOrders } from 'store/actions';
import { ordersService } from 'store/services';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    fontSize: 13
  },
}));

function OrderTable (props) {

  const classes = useStyles()
  const { orders, confirm, reFetchOrders } = props
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = event => {

    let selectedRows;

    if (event.target.checked) {
      selectedRows = orders.map(item => item.id);
    } else {
      selectedRows = [];
    }

    setSelectedRows(selectedRows);
  };

  const handleSelectOne = (id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelectedRows);
  };

  const handleDelete = ev => {

    confirm(`确认删除这${selectedRows.length}个订单吗？`)
      .then(res => {
        ordersService.deleteOrder(selectedRows)
            .then(res => {
              if (res.status === 0) {
                alert({type: 'success', message: '订单删除成功..'})
                reFetchOrders()
              } else {
                return Promise.reject(new Error(res.message))
              }
            })
            .catch(err => {
              alert({ type: 'error', message: `订单删除失败, 原因: ${err.message}` })
            })
        })
  }

  return (
    <div className={classes.root}>
      <DeleteOrder
        visible={selectedRows.length > 0}
        handleDelete={handleDelete}
      />
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedRows.length === orders.length}
                  color="primary"
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length < orders.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>订单号</TableCell>
              <TableCell>用户名</TableCell>
              <TableCell>订单状态</TableCell>
              <TableCell>付款方式</TableCell>
              <TableCell>运输方式</TableCell>
              <TableCell>订单金额</TableCell>
              <TableCell>实收金额</TableCell>
              <TableCell>下单时间</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.root}>
            {
              orders.map((order, index) => (
                <OrderRow
                  order={order}
                  key={order.id}
                  handleChecked={handleSelectOne}
                  checked={selectedRows.indexOf(order.id) !== -1}
                />
              ))
            }
          </TableBody>
          <TableFooter orders={orders}/>
        </Table>
      </Paper>
    </div>
  )
}

const mapStateToProps = state => ({
  currencyList: state.global.currencyList
})

const mapDispatchToProps = dispatch => ({
  alert: options=> dispatch(alert(options)),
  confirm: message => dispatch(confirm(message)),
  fetchOrders: options => dispatch(fetchOrders(options))
})

const connectedOrderTable = connect(mapStateToProps, mapDispatchToProps)(OrderTable)

export default connectedOrderTable;
