import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { SearchInput } from 'components';
import { Select } from 'components'
import { statusList } from '../status'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  flex: {
    display: 'flex',
    flexDirection: 'row'
  },
  display: {
    marginLeft: theme.spacing(2)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
}));

const Toolbar = props => {
  const { className, handleChange, display, setDisplay, ...rest } = props;

  const classes = useStyles();

  const [search, setValue] = useState('')
  const [status, setStatus] = useState('')

  const handleInputChange = ev => {
    setValue(ev.target.value)
  }

  const handleSelect = ev => {
    const value = ev.target.value
    setStatus(value)
    handleChange({ name: 'trackStatus', value })
  }

  const handleDisplayChange = ev => {
    setDisplay(ev.target.value)
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <Select
          options={statusList}
          name="status"
          value={status}
          onChange={handleSelect}
        />
        <SearchInput
          name="search"
          value={search}
          className={classes.searchInput}
          placeholder="用户名, 产品名称，型号..."
          onChange={handleInputChange}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={ev => handleChange({ name: 'search', value: search })}
        >
          搜索
        </Button>
        <RadioGroup value={display} onChange={handleDisplayChange} className={clsx(classes.display, classes.flex)}>
          <FormControlLabel value="table" control={<Radio />} label="表格显示" />
          <FormControlLabel value="list" control={<Radio />} label="列表显示" />
        </RadioGroup>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  search: PropTypes.string,
  handleChange: PropTypes.func
};

export default Toolbar;
