import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { useParams, Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField,
  Breadcrumbs,
  Typography,
} from '@material-ui/core';

import browserHistory from 'common/history'
import { Upload, ImageInput } from './components'
import { productsService, adsService } from 'store/services'
import { fetchAd } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
}));

const schema = {
  adName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  url: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  img: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  title: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  adType: {
    presence: { allowEmpty: false, message: 'is required' },
  }
};

const adTypes = ['auth', 'banner']

const Ad = props => {

  const { fetchAd, ad } = props
  const classes = useStyles();
  const { id } = useParams()

  const [uploading, setUploading] = useState(false)
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      adType: 'auth'
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  useEffect(() => {
    parseInt(id) && fetchAd(id)
  }, [id, fetchAd])

  useEffect(() => {
    parseInt(id) && setFormState(formState => ({
      ...formState,
      values: ad
    }));
  }, [id, ad])

  const handleChange = event => {

    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  function handleUpload (ev) {

    const files = ev.target.files
    const formData = new FormData()
    formData.append('name', formState.values.adName)
    formData.append('file', files[0])
    
    setUploading(true)
    productsService.upload(formData)
      .then(res => {
        if (res.status === 0 && res.data.success) {
          setFormState({
            ...formState,
            values: {
              ...formState.values,
              img: res.data.url
            }
          })
          setUploading(false)
        } else {
          return Promise.reject(new Error('我去, 上传失败了'))
        }
      })
      .catch(err => {
        setUploading(false)
        alert({type: 'error', message: err.message})
      })
  }

  const handleSave = ev => {
    if (formState.isValid) {
      const save = id === 'new' ? adsService.createAd : adsService.patchAd
      save(formState.values)
        .then(res => {
          if (res.status === 0) {
            browserHistory.replace('/ads')
          }
        })
    }
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          首页
        </Link>
        <Link color="inherit" to="/ads">
          广告列表
        </Link>
        <Typography color="textPrimary">{id === 'new' ? '新增广告' : '编辑广告'}</Typography>
      </Breadcrumbs>
      <Card className={classes.content}>
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="带*号为必填项"
            title="广告信息"
          />
          <Divider />
          <CardContent>
            <Grid
                container
                spacing={3}
              >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="广告类型"
                  margin="dense"
                  name="adType"
                  onChange={handleChange}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={formState.values.adType || ''}
                  variant="outlined"
                >
                  {adTypes.map(option => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText="Please specify the first name"
                  label="广告名称"
                  margin="dense"
                  name="adName"
                  onChange={handleChange}
                  required
                  value={formState.values.adName || ''}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>
      <Card
        className={classes.content}
      >
        <CardHeader
          title={<Typography variant="h5" component="h2">广告商品信息</Typography>}
          subheader="建议上传白底图, 尺寸400x400以上"
        />
        <Divider/>
        <CardContent>
          <Upload label="上传广告图片" handleChange={handleUpload} id="primary-upload-button" />
          <ImageInput image={{url: formState.values.img, loading: uploading }} />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="商品地址"
                margin="dense"
                name="url"
                onChange={handleChange}
                required
                value={formState.values.url || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="广告标题"
                margin="dense"
                name="title"
                onChange={handleChange}
                required
                value={formState.values.title || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="副标题"
                margin="dense"
                name="subtitle"
                onChange={handleChange}
                type="number"
                value={formState.values.subtitle || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        disabled={!formState.isValid}
        onClick={handleSave}
      >
        保存
      </Button>
    </div>
  );
};

Ad.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  ad: state.global.ad,
})

const mapDispatchToProps = dispatch => ({
  fetchAd: id => dispatch(fetchAd(id)),
})

const connectedAd = connect(mapStateToProps, mapDispatchToProps)(Ad)

export default connectedAd
