import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom'
import {
  Breadcrumbs,
  Typography,
  Button
} from '@material-ui/core';

import {
  OrderStatus,
  Address,
  Info,
  OrderTable,
  QuotaDialog,
  QuotaTable,
  Financial,
  Message,
  Payment,
  statusClass,
  OrderAmount,
  Container
} from './components'

import { fetchOrder, updateOrder, confirm, confirmOrder, alert } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    fontSize: 13
  },
  content: {
    marginTop: theme.spacing(3),
  },
  inner: {
    width: '100%',
  },
  table: {
    width: '100%',
    borderTop: '1px solid #e5e5e5',
  },
  fixedLayout: {
    tableLayout: 'fixed'
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1)
  }
}));

function Order (props) {

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { order, patching, financial } = props
  const { fetchOrder, updateOrder, confirm, alert, confirmOrder } = props
  const { id } = useParams()
  const date = new Date().toISOString()
  // const disabled = order.trackStatus !== statusClass.WAIT_SALLER_CONFIRM

  const getMessage = orderStatus => {
    switch (orderStatus) {
      case statusClass.WAIT_BUYER_CONFIRM:
        return '确定驳回该订单到审核吗？'
      case statusClass.WAIT_SALLER_CONFIRM:
        return '请确认已经提供【运费，折扣】信息，确认之后将进入【买家付款阶段】'
      case statusClass.WAIT_PAY:
        return '请确认已经【收到买家付款】，确认之后将进入【发货阶段】'
      case statusClass.WAIT_DELIVERY:
        return '请确认已经提供【包裹，重量，尺寸】信息，确认之后将进入【待签收阶段】'
      default:
        return '该订单即将完成'
    }
  }

  useEffect(() => {
    fetchOrder(id)
  }, [id, fetchOrder])

  const handleConfirm = ev => {
    confirm(getMessage(order.trackStatus))
      .then(() => {
        confirmOrder(Object.assign(order, {financial, trackStatus: statusClass.WAIT_PAY, stockConfirmDate: date}))
      })
      .catch(err => {
        console.error(err)
      })
  }
  // 驳回订单
  const handleRereject = ev => {
    confirm(getMessage(statusClass.WAIT_BUYER_CONFIRM))
      .then(res => {
        confirmOrder(Object.assign(order, { trackStatus: statusClass.WAIT_SALLER_CONFIRM }))
      })
  }

  const handlePay = ev => {
    if (order.receivedPayment === 0) {
      alert({
        type: 'error',
        message: '实收金额不能为空'
      })
    } else {
      confirmOrder(Object.assign(order, { trackStatus: statusClass.WAIT_DELIVERY, paymentDate: date }))
    }
  }

  const handleDelivery = ev => {
    confirmOrder(Object.assign(order, { trackStatus: statusClass.WAIT_RECEIVE, deliveryDate: date }))
  }

  const handleFinish = ev => {
    confirmOrder(Object.assign(order, { trackStatus: statusClass.COMPLETE }))
  }

  const ActionButton = props => {

    const { orderStatus } = props

    switch (orderStatus) {
      case statusClass.WAIT_SALLER_CONFIRM:
        return (
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirm}
            className={classes.button}
            disabled={patching}
          >确认订单</Button>
        )
      case statusClass.WAIT_PAY:
        return (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={handlePay}
              className={classes.button}
              disabled={patching}
            >确认买家付款</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleRereject}
              className={classes.button}
            >驳回审核</Button>
          </>
        )
      case statusClass.WAIT_DELIVERY:
        return (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={handleDelivery}
              className={classes.button}
              disabled={patching}
            >发货</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleRereject}
              className={classes.button}
            >驳回审核</Button>
          </>
        )
      default:
        return (
          <Button
            color="primary"
            variant="contained"
            onClick={handleFinish}
            className={classes.button}
            disabled={patching}
          >完成订单</Button>
        )
    }
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          首页
        </Link>
        <Link color="inherit" to="/orders">
          订单列表
        </Link>
        <Typography color="textPrimary">{order._orderId}</Typography>
      </Breadcrumbs>
      <div className={classes.content}>
        <OrderStatus order={order}/>
        <Address order={order}/>
        <Info order={order}/>
        <OrderTable order={order} showDialog={setOpen}/>
        <OrderAmount/>
        { order.trackStatus > statusClass.WAIT_PAY ? <Financial updateOrder={updateOrder}/> : null}
        { order.trackStatus === statusClass.WAIT_PAY ? <Payment order={order} updateOrder={updateOrder}/> : null}
        <Message order={order} updateOrder={updateOrder}/>
        { order.receipts ? <Container title="付款截图"><img src={order.receipts} alt="" /></Container> : null }
        <ActionButton orderStatus={order.trackStatus}/>
      </div>
      <QuotaDialog open={open} setOpen={setOpen}>
        <QuotaTable userId={order.user.id}/>
      </QuotaDialog>
    </div>
  )
}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  order: state.orders.order,
  patching: state.orders.patching,
  financial: state.orders.financial
})

const mapDispatchToProps = dispatch => ({
  fetchOrder: id => dispatch(fetchOrder(id)),
  updateOrder: payload => dispatch(updateOrder(payload)),
  alert: options => dispatch(alert(options)),
  confirm: options => dispatch(confirm(options)),
  confirmOrder: order => dispatch(confirmOrder(order))
})

const connectedOrder = connect(mapStateToProps, mapDispatchToProps)(Order)

export default connectedOrder
