import { globalConst } from '../constants';
import { adsService, messagesService } from '../services';

export function showLoading () {
  return {
    type: globalConst.SHOW_LOADING
  }
}

export function closeLoading () {
  return {
    type: globalConst.CLOSE_LOADING
  }
}

// 广告
export function fetchAds () {

  return (dispatch, getState) => {

    const { auth } = getState()
    if (auth.loggingIn) return

    adsService.fetchAds()
      .then(res => {
        if (res.status === 0) {
          dispatch(fetchAdsSuccess(res.data))
        }
      })
  }

  function fetchAdsSuccess ([ads, totalCount]) {
    return {
      type: globalConst.GET_AD_LIST,
      ads,
      totalCount
    }
  }
}

export function fetchAd (id) {

  return dispatch => {

    adsService.fetchAd(id)
      .then(res => {
        if (res.status === 0) {
          dispatch(fetchAdSuccess(res.data))
        }
      })
  }

  function fetchAdSuccess (ad) {
    return {
      type: globalConst.GET_AD,
      ad
    }
  }
}

// 留言
export function fetchMessages (options) {

  return (dispatch, getState) => {

    const { auth } = getState()
    if (auth.loggingIn) return

    messagesService.fetchMessages(options)
      .then(res => {
        if (res.status === 0) {
          dispatch(fetchSuccess(res.data))
        }
      })
  }

  function fetchSuccess ({totalCount, messages}) {
    return {
      type: globalConst.GET_MESSAGE_LIST,
      totalCount,
      messages,
    }
  }
}

// 未读留言
export function fetchUnreadMessages () {

  return (dispatch, getState) => {

    // const { auth } = getState()
    // if (auth.loggingIn) return

    messagesService.fetchUnreadMessages()
      .then(res => {
        if (res.status === 0) {
          dispatch(fetchSuccess(res.data))
        }
      })
  }

  function fetchSuccess (count) {
    return {
      type: globalConst.GET_UNREAD_MESSAGE_COUNT,
      count,
    }
  }
}

// 留言
export function fetchMessage (id) {

  return (dispatch, getState) => {

    dispatch(fetchSuccess(id))
  }

  function fetchSuccess (id) {
    return {
      type: globalConst.GET_MESSAGE,
      id,
    }
  }
}

// 留言
export function updateMessage (id, body) {

  return (dispatch, getState) => {

    const { auth } = getState()
    if (auth.loggingIn) return

    messagesService.patchMessage(id, body)
      .then(res => {
        if (res.status === 0) {
          dispatch(patchSuccess(body))
        }
      })
  }

  function patchSuccess (message) {
    return {
      type: globalConst.UPDATE_MESSAGE,
      message,
    }
  }
}