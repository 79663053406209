import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Collapse, Paper, FormControl, FormHelperText, Input, Button, } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 420
  },
  paper: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  highlight: {
    fontWeight: 500
  }
}));

const PriceInput = props => {
  const { className, handleSave, handleChange, visible, style, ...rest } = props;

  const classes = useStyles();
  const [value, setValue] = useState('')

  const onChange = ev => {
    setValue(ev.target.value)
    handleChange(+ev.target.value)
  }

  return (
    <Collapse in={visible} className={classes.root}>
      <Paper
        {...rest}
        className={clsx(classes.paper, className)}
        style={style}
      >
        <FormControl fullWidth>
          <Input
            {...rest}
            value={value}
            placeholder="利润率:填入小数, 例如5%，填入0.05"
            className={classes.input}
            disableUnderline
            onChange={onChange}
          />
          <FormHelperText>批量编辑选中的货品的利润率会直接修改其<span className={classes.highlight}>销售价</span></FormHelperText>
        </FormControl>
        <Button
          color="primary"
          variant="contained"
          onClick={ev => handleSave(value)}
        >
          保存
        </Button>
      </Paper>
    </Collapse>
  );
};

PriceInput.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSave: PropTypes.func,
  style: PropTypes.object
};

export default PriceInput;
