import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import PhotoIcon from '@material-ui/icons/Photo'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DraftsIcon from '@material-ui/icons/Drafts';
import MessageIcon from '@material-ui/icons/Message';
import TagIcon from '@material-ui/icons/TagFaces'

import { Profile, SidebarNav } from './components';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, unreadMessageCount } = props;

  const classes = useStyles();

  const pages = [
    {
      title: '首页',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: '用户',
      href: '/users',
      icon: <PeopleIcon />
    },
    {
      title: '产品',
      href: '/products',
      icon: <ShoppingBasketIcon />
    },
    {
      title: '草稿箱',
      href: '/drafts',
      icon: <DraftsIcon />
    },
    {
      title: '订单',
      href: '/orders',
      icon: <ListAltIcon />
    },
    {
      title: '广告管理',
      href: '/ads',
      icon: <PhotoIcon />
    },
    {
      title: '留言管理',
      href: '/messages',
      icon: <MessageIcon />,
      badge: unreadMessageCount
    },
    {
      title: '标签管理',
      href: '/tags',
      icon: <TagIcon />
    },
    {
      title: 'Typography',
      href: '/typography',
      icon: <TextFieldsIcon />
    },
    {
      title: 'Icons',
      href: '/icons',
      icon: <ImageIcon />
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  unreadMessageCount: state.global.unreadMessageCount,
})

export default connect(mapStateToProps)(Sidebar);
