import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
} from '@material-ui/core'

import { updateProduct, uploadPackage } from 'store/actions'
import { Upload, ImageInput } from '..'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const Package = props => {

  const classes = useStyles();
  const { className, product, loading } = props
  const { uploadPackage, updateProduct } = props

  const handleUpload = ev => {
    const file = ev.target.files[0]
    uploadPackage(file)
  }

  const handleChange = ev => {
    updateProduct({
      ...product,
      package: ev.target.value
    })
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Card
        className={classes.content}
      >
        <CardHeader
          title={<Typography variant="h5" component="h2">包装图</Typography>}
        />
        <Divider/>
        <CardContent>
          <Upload label="上传包装图" handleChange={handleUpload} id="package-upload-button" />
          <ImageInput image={{url: product.package || '', loading }} handleChange={handleChange}/>
        </CardContent>
      </Card>
    </div>
  )
}

const mapStateToProps = state => ({
  product: state.product.product,
  loading: state.product.uploadingPackage
})

const mapDispatchToProps = dispatch => ({
  updateProduct: product => dispatch(updateProduct(product)),
  uploadPackage: body => dispatch(uploadPackage(body))
})

const connectedPackage = connect(mapStateToProps, mapDispatchToProps)(Package)


connectedPackage.propTypes = {
  className: PropTypes.string,
  updateProduct: PropTypes.func
};

export default connectedPackage