import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab'
import {
  TextField,
  Avatar,
  IconButton,
} from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    position: 'relative'
  },
  image: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(1)
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const ImageInput = props => {

  const classes = useStyles();
  const { className, image } = props
  const { handleRemove, handleChange } = props

  return (
    <div className={clsx(className, classes.root)}>
      {
        image.loading
          ? <Skeleton
              className={classes.image}
              animation="wave"
              variant="rect"
              width={100}
              height={100}
            />
          : <Avatar
              className={classes.image}
              src={image.url + '?imageView2/1/w/100/h/100/ignore-error/1'}
              variant="square"
            />
      }
      <TextField value={image.url || ''} fullWidth variant="outlined" size="small" onChange={handleChange} />
      <IconButton className={classes.close} onClick={handleRemove}>
        <CloseRounded/>
      </IconButton>
    </div>
  )
}

ImageInput.propTypes = {
  className: PropTypes.string,
  handleRemove: PropTypes.func,
  image: PropTypes.object
}

export default ImageInput