import React from 'react';
// import { makeStyles } from '@material-ui/styles';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Button
} from '@material-ui/core'

import { Image } from './components'

export default function Toolbar(props) {
  const { open, images, handleConfirm, handleClose } = props

  return (
    <Dialog
      fullWidth
      open={open}
      scroll="paper"
    >
      <DialogTitle>插入详情图片</DialogTitle>
      <DialogContent dividers>
        <List>
          {
            images.map((image, index) => <Image image={image} key={index} /> )
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          取消
        </Button>
        <Button onClick={handleConfirm} color="primary">
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
}