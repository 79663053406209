import React, { useState, useEffect, useRef, } from 'react';
import { makeStyles } from '@material-ui/styles';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';

import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'

import Modules from './Modules'
import { ImageViews } from './components'
import { handleUpload, updateProduct } from 'store/actions';
// import ImageBlot from './Blot';

// Quill.register(ImageBlot);

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2),
    '& .ql-editor': {
      overflow: 'unset'
    }
  },
}));

function Editor (props) {

  const classes = useStyles();
  const { product, updateProduct } = props
  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const [hasWaterMark, setWaterMark] = useState(false)
  const editorRef = useRef(null)

  const uploadHandler = (args) => {

    const input = document.createElement('input')
  
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.setAttribute('multiple', true)
    input.onchange = ev => {
      setOpen(true)
      const files = ev.target.files
      setImages([...files].map(_ => ({url: ''})))
      Promise.all([...files].map(handleUpload))
        .then(results => {
          const images = results.map(res => res.data)
          setImages(images.reverse())
        })
    }
    input.click()
  }

  useEffect(() => {
    if (typeof editorRef.current.getEditor !== 'function') return
    const toolbar = editorRef.current.getEditor().getModule('toolbar')
    toolbar.addHandler('image', uploadHandler)
  })

  const insertImages = () => {
    const editor = editorRef.current.getEditor()
    images.forEach(image => {
      const range = editor.getSelection(true)
      const url = hasWaterMark ? image.url + '-detailview' : image.url
      if (range) {
        editor.insertEmbed(range.index, 'image', url, 'user')
        editor.insertText(range.index + 1, '\n', 'user')
      } else {
        editor.insertEmbed(editor.getLength(), 'image', url, 'user')
        editor.insertText(range.index + 1, '\n', 'user')
      }
    })
    setOpen(false)
  }

  const handleChange = (value, delta, source) => {
    if (source === 'user') {
      updateProduct({
        ...product,
        description: value
      })
    }
  }

  const handleWaterMark = ev => {
    setWaterMark(ev.target.checked)
  }

  const WaterMarkControl = <FormControlLabel
    control={<Checkbox checked={hasWaterMark} onChange={handleWaterMark} name="waterMark" />}
    label="添加水印"
  />

  return (
    <>
      <ImageViews
        open={open}
        images={images}
        handleConfirm={insertImages}
        handleClose={() => setOpen(false)}
      />
      <Card
        className={classes.content}
      >
        <CardHeader
          title={<Typography variant="h5" component="h2">详情</Typography>}
          subheader={WaterMarkControl}
        />
        <Divider/>
        <CardContent>
          <ReactQuill
            ref={editorRef}
            theme="snow"
            debug="info"
            value={product.description}
            onChange={handleChange}
            modules={Modules}
          />
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = state => ({
  loaded: state.product.loaded,
  product: state.product.product,
})

const mapDispatchToProps = dispatch => ({
  updateProduct: product => dispatch(updateProduct(product)),
})

const connectedEditor = connect(mapStateToProps, mapDispatchToProps)(Editor)

export default connectedEditor