import request from '../../common/request'

export const userService = {
  login,
  checkAuth,
  fetchUser,
  fetchUserList,
  patchUser,
  register,
  sendEmail,
  verifyLink
}

function login (payload) {
  return request.post({
    url: '/v2/login',
    body: payload
  })
}

function register (payload) {
  return request.post({
    url: '/v2/users',
    body: payload
  })
}

function sendEmail (body) {
  return request.post({
    url: '/v2/sendemail',
    body
  })
}

function verifyLink (params) {
  return request.get({
    url: `/v2/registerinfo`,
    params
  })
}


function checkAuth () {
  return request.get({
    url: '/v2/authvalidate'
  })
}

function fetchUser (userId) {
  return request.get({
    url: `/v2/cms/user/${userId}`
  })
}

function fetchUserList (options = {}) {
  return request.get({
    url: '/v2/cms/userList',
    params: options
  })
}

function patchUser (id, user) {
  return request.put({
    url: `/v2/cms/user/${id}`,
    body: user
  })
}