import { combineReducers } from 'redux'

import { auth } from './auth.reducer'
import { alert } from './alert.reducer'
import { confirm } from './confirm.reducer'
import { users } from './users.reducer'
import { user } from './user.reducer'
import { quota } from './quota.reducer'
import { brands } from './brands.reducer'
import { products } from './products.reducer'
import { product } from './product.reducer'
import { global } from './global.reducer'
import { caches } from './cache.reducer'
import { orders } from './orders.reducer'

const rootReducer = combineReducers({
  global,
  auth,
  alert,
  confirm,
  users,
  user,
  quota,
  brands,
  products,
  product,
  caches,
  orders
})

export default rootReducer