import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  paper: {
    height: '100%',
    padding: theme.spacing(2),
  },
  paperContent: {
    paddingTop: 12
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  key: {
    width: 50,
    flex: 'none',
  }
}));

function getAddress (address) {
  return [
    'address',
    'address2',
    'city',
    'state',
    'country'
  ].map(s => address[s]).filter(s => !!s).join(', ')
}

export default function Address (props) {

  const classes = useStyles();
  const { order } = props

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">收货信息</Typography>
            {
              order.address
              ? (
                <div className={classes.paperContent}>
                  <div className={classes.flex}>
                    <Typography variant="body2" className={classes.key}>收货人: </Typography>
                    <Typography variant="subtitle2">{`${order.address.firstname || ''} ${order.address.lastname || ''}`}</Typography>
                  </div>
                  <div className={classes.flex}>
                    <Typography variant="body2" className={classes.key}>电&emsp;话: </Typography>
                    <Typography variant="subtitle2">{order.address.phone}</Typography>
                  </div>
                  <div className={classes.flex}>
                    <Typography variant="body2" className={classes.key}>地&emsp;址: </Typography>
                    <Typography variant="subtitle2">{getAddress(order.address || [])}</Typography>
                  </div>
                  <div className={classes.flex}>
                    <Typography variant="body2" className={classes.key}>邮&emsp;编: </Typography>
                    <Typography variant="subtitle2">{order.address.zip}</Typography>
                  </div>
                </div>
              ) : null
            }
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6">客户信息</Typography>
            <div className={classes.paperContent}>
              <div className={classes.flex}>
                <Typography variant="body2" className={classes.key}>用户名: </Typography>
                <Typography variant="subtitle2">{order.user.userName}</Typography>
              </div>
              <div className={classes.flex}>
                <Typography variant="body2" className={classes.key}>所在地: </Typography>
                <Typography variant="subtitle2">{order.user.country}</Typography>
              </div>
              <div className={classes.flex}>
                <Typography variant="body2" className={classes.key}>邮&emsp;箱: </Typography>
                <Typography variant="subtitle2">{order.user.email}</Typography>
              </div>
              <div className={classes.flex}>
                <Typography variant="body2" className={classes.key}>电&emsp;话: </Typography>
                <Typography variant="subtitle2">{order.user.mobile}</Typography>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
