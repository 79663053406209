import request from 'common/request'

export const messagesService = {
  fetchMessages,
  fetchMessage,
  fetchUnreadMessages,
  patchMessage,
}

function fetchMessages (options) {
  return request.get({
    url: '/v2/userMessages',
    params: options
  })
}

function fetchUnreadMessages () {
  return request.get({
    url: '/v2/unreadUserMessagesCount',
  })
}

function fetchMessage (id) {
  return request.get({
    url: `/v2/userMessages/${id}`,
  })
}

function patchMessage (id, body) {
  return request.put({
    url: `/v2/userMessages/${id}`,
    body
  })
}