import * as React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import { Button,} from '@material-ui/core';
import { ProductTable } from './components'
import { Container, statusClass } from '..'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    button: {
      marginTop: theme.spacing(1)
    }
  })
)

export default function OrderTable (props) {

  const classes = useStyles()
  const { order, showDialog } = props

  return (
    <Container title="商品信息">
      <Button
        color="primary"
        variant="contained"
        onClick={ev => showDialog(true)}
        size="small"
        className={classes.button}
        disabled={order.trackStatus !== statusClass.WAIT_SALLER_CONFIRM}
      >
        添加产品
      </Button>
      <ProductTable order={order} className={classes.root} statusClass={statusClass}/>
    </Container>
  )
}