import React from 'react';
import XLSX from 'xlsx';
import { connect } from 'react-redux';

import {
  Button,
} from '@material-ui/core';
import { alert, fetchQuota } from 'store/actions';

function getFileName (data) {

  const { user } = data;
  return `${user.userName}-quotation.xlsx`;
}

function getData (props) {

  let { items } = props
  let cols = ['Model Id', 'Product Name', 'Color', 'Price', 'Weight', 'Remark']

  let data = items.map(item => [
    item.skuId,
    item.modelName,
    item.spec,
    item.salesPrice,
    item.weight,
    item.remark,
  ])

  return [cols, ...data]
}

function Export (props) {

  const { className, user, fetchQuota, alert } = props;

  const handleClick = ev => {
    
    alert({type: 'info', message: '正在生成表格，请勿离开...'})

    fetchQuota(user.id, {pageSize: 5000}).then(res => {
      try {
        const fileName = getFileName(res)
        const data = getData(res)
        const wsName = 'SheetJS'
      
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(data)
  
        console.log('start writing to wb')
    
        XLSX.utils.book_append_sheet(wb, ws, wsName)
        console.log('start writing file')
        XLSX.writeFile(wb, fileName)
      } catch (err) {
        console.error(err)
        alert({type: 'error', message:`生成表格出错, 原因: ${err.message}`})
      }
      console.log(res)
    })
  }

  return (
    <div className={className}>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={handleClick}
      >
        一键导出报价表
      </Button>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.quota.user,
});

const mapDispatchToProps = dispatch => ({
  alert: payload => dispatch(alert(payload)),
  fetchQuota: (userId, options) => dispatch(fetchQuota(userId, options)),
});

const connectedExport = connect(mapStateToProps, mapDispatchToProps)(Export);

export default connectedExport;