import { userConst } from '../constants';

export function user (state = { loading: false, user: null, error: '' }, action) {
  switch (action.type) {
    case userConst.USER_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case userConst.USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        user: action.user
      });
    case userConst.USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    default:
      return state
  }
}