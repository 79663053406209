import request from '../../common/request'

export const productsService = {
  fetchProducts,
  patchProducts,
  fetchProduct,
  upload,
  addProducts,
  fetchTags,
  deleteProduct,
  saveTag,
  removeTagFromProduct,
  cleanData,
  cleanOrderData,
  fixGUID,
  fetchProductsBySkuIds
}

function fetchProducts (options) {
  return request.get({
    url: '/v2/cms/products',
    params: options
  })
}

function patchProducts (products) {
  return request.put({
    url: '/v2/cms/products',
    body: products
  })
}

function addProducts (products) {
  return request.post({
    url: '/v2/cms/products',
    body: products
  })
}

function fetchProduct (id) {
  return request.get({
    url: `/v2/cms/products/${id}`,
  })
}

function deleteProduct (id) {
  return request.delete({
    url: `/v2/cms/products/${id}`,
  })
}

function upload (body) {
  return request.post({
    url: '/v2/upload',
    headers: new Headers(),
    multiple: true,
    body: body
  })
}

function fetchTags () {
  return request.get({
    url: `/v2/tags`,
  })
}

function saveTag (tag) {
  return request.post({
    url: `/v2/cms/tags`,
    body: tag
  })
}

function removeTagFromProduct (option) {
  return request.put({
    url: `/v2/cms/deleteProductTag`,
    body: option
  })
}

function cleanData () {
  return request.put({
    url: `/v2/cms/cleanData`
  })
}

function cleanOrderData () {
  return request.put({
    url: `/v2/cms/cleanOrderData`
  })
}

function fixGUID () {
  return request.get({
    url: `/v2/cms/fixGUID`
  })
}

function fetchProductsBySkuIds (skuIds, userId) {

  return request.post({
    url: '/v2/cms/quotationBySkuIds',
    params: {userId},
    body: skuIds
  })
}