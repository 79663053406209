import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { ProductsTable } from './components';
import { getCaches } from '../../store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const Drafts = (props) => {
  const classes = useStyles();

  const { getCaches } = props

  useEffect(() => {
    getCaches()
  }, [getCaches])

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <ProductsTable/>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  caches: state.caches.caches,
})

const mapDispatchToProps = dispatch => ({
  getCaches: () => dispatch(getCaches()),
})

const connectedDrafts = connect(mapStateToProps, mapDispatchToProps)(Drafts)

export default connectedDrafts
