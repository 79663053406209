import { userConst } from '../constants';

const defaultState = {
  loggedIn: false,
  loggingIn: true,
  emailSent: false,
  validLink: false,
  verifyingLink: false,
  registering: false,
  registerSuccess: false,
  user: {}
}

export function auth (state = defaultState, action) {
  switch (action.type) {
    case userConst.AUTH_REQUEST:
      return Object.assign({}, state, {
        loggedIn: false,
        authorized: false,
        loggingIn: true,
      });
    case userConst.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loggedIn: true,
        loggingIn: false,
        authorized: false,
        user: action.user
      });
    case userConst.LOGIN_FAILURE:
      return Object.assign({}, state, {
        loggingIn: false,
        loggedIn: false,
      });
    case userConst.AUTH_SUCCESS:
      return Object.assign({}, state, {
        loggedIn: true,
        loggingIn: false,
        authorized: true,
        user: action.user
      });
    case userConst.AUTH_FAILURE:
      return Object.assign({}, state, {
        loggingIn: false,
        loggedIn: true,
        user: action.user
      });
    case userConst.LOGOUT:
      return Object.assign({}, state, {
        loggedIn: false,
      });
      case userConst.SEND_EMAIL:
        return Object.assign({}, state, {
          emailSent: true,
        });
      case userConst.REFRESH_SEND_EMAIL:
        return Object.assign({}, state, {
          emailSent: false,
        });
      case userConst.VERIFY_EMAIL:
        return Object.assign({}, state, {
          validLink: action.validLink,
          verifyingLink: false
        });
      case userConst.VERIFY_EMAIL_REQUEST:
        return Object.assign({}, state, {
          verifyingLink: true,
        });
      case userConst.REGISTER_REQUEST:
        return Object.assign({}, state, {
          registering: true,
        });
      case userConst.REGISTER_SUCCESS:
        return Object.assign({}, state, {
          registering: false,
          registerSuccess: true,
        });
    default:
      return state
  }
}