import * as React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { TableRow, TableCell, TableFooter } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      border: 0
    },
  }),
)
export default function Footer (props) {

  const classes = useStyles()
  const { order } = props

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={7} className={classes.root}>
          <Link to={`/orders/${order._orderId}`}>
            订单详情 >>
          </Link>
        </TableCell>
      </TableRow>
    </TableFooter>
  )
}
