import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';

import { muateQuotaUser, patchUser } from 'store/actions'
import { Select } from 'components'

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {

  const { className, user, muateQuotaUser, patchUser, currencyList } = props;
  const classes = useStyles();

  const [payload, setPayload] = useState({})
  const handleChange = event => {
    muateQuotaUser({
      ...user,
      [event.target.name]: event.target.value
    })
    setPayload({
      ...payload,
      [event.target.name]: event.target.value
    })
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <form
        autoComplete="off"
        noValidate
      >
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="利润率，5%的利润率请填0.05"
                margin="dense"
                name="marginRate"
                required
                onChange={handleChange}
                value={user.marginRate}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <Select
                options={currencyList}
                name="currencyCode"
                defaultValue="结算货币"
                value={user.currencyCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="计算汇率"
                margin="dense"
                name="exchangeRate"
                onChange={handleChange}
                required
                value={user.exchangeRate}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={ev => patchUser(user.id, payload)}
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool
};

const mapStateToProps = state => ({
  user: state.quota.user,
  loading: state.quota.loadingQuota,
  currencyList: state.global.currencyList
})

const mapDispatchToProps = dispatch => ({
  muateQuotaUser: user => dispatch(muateQuotaUser(user)),
  patchUser: (id, payload) => dispatch(patchUser(id, payload)),
})

const connectedAccountDetails = connect(mapStateToProps, mapDispatchToProps)(AccountDetails)

export default connectedAccountDetails;
