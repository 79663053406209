import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField
} from '@material-ui/core';

import { Container } from '..'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(2)
  },
}));

export default function Message (props) {

  const classes = useStyles();
  const { order } = props
  const { updateOrder } = props

  const handleChange = ev => {
    updateOrder({
      ...order,
      [ev.target.name]: ev.target.value
    })
  }

  return (
    <Container title="给客户留言">
      <TextField
        className={classes.textField}
        fullWidth
        multiline
        name="sellerRemark"
        value={order.sellerRemark}
        onChange={handleChange}
      />
    </Container>
  );
}
