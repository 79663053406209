import { userConst } from '../constants';
import { userService } from '../services';
import { alert } from './'

/**
 * an Action is payload of information that send data from your application to your store
 * it is the only source of information for the store
 * we send them to the store using store.dispatch()
 */


/**
 * action creators are functions that crfeate actions
 */

export function fetchUsers (options) {

  return (dispatch, getState) => {

    const { auth, users } = getState()
    if (auth.loggingIn || users.loading) return

    dispatch(startRequest())
    dispatch(alert({type: 'info', message: '正在请求数据, 请勿操作...'}))

    userService.fetchUserList(options)
      .then(res => {
        if (res.status === 0) {
          dispatch(success(res.data))
          dispatch(alert({type: 'success', message: '加载成功..'}))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({ type: 'error', message: `加载失败, 原因: ${err.message}` }))
        dispatch(fail(err.message))
      })
  }
}

function startRequest () {
  return {
    type: userConst.GET_REQUEST
  }
}

function success ({users, totalCount}) {
  return {
    type: userConst.GET_SUCCESS,
    users,
    totalCount
  }
}

function fail (error) {
  return {
    type: userConst.GET_FAILURE,
    error
  }
}