import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import XLSX from 'xlsx';
import { groupBy } from 'lodash'
import { Button, Breadcrumbs, Typography, Paper } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { addProduct } from 'store/actions'
import { Upload, ProductsTable } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flex: 1
  },
  content: {
    marginTop: theme.spacing(3)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3)
  }
}));

const toJson = wb => {
  let results = []
  wb.SheetNames.forEach(sheetName => {
    const roa = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { header : 1 });
    if(roa.length) {
      let [keys, ...rows] = roa
      rows = rows.filter(row => row.length > 0)
      results = rows.map(getRow)

      function getRow (data) {
        let row = {}
        keys.forEach((key, index) => {
          row[key] = data[index]
        })
        return row
      }
    }
  });
  return results
}

const format = data => {
  let groupData = groupBy(data, 'modelId')
  let keys = Object.keys(groupData)
  return keys.map(key => {
    let item = data.find(item => item.modelId.toString() === key)
    let { modelId, modelName, cnName, weight, qtyPerCtn, brand, category, L='', W='', H='' } = item
    let size = `${L}x${W}x${H}`
    let row = { modelId, modelName, cnName, size, weight, qtyPerCtn, brand, category }
    row.items = groupData[key]
    return row
  })
}

const Import = (props) => {

  const classes = useStyles();
  const { addProduct } = props
  const [products, setProducts] = useState([])

  const handleUpload = (e) => {

    e.stopPropagation(); e.preventDefault();

    const files = e.target.files, f = files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {type: 'array'});

      const products = format(toJson(workbook))
      setProducts(products)
    };
    reader.readAsArrayBuffer(f);
  }

  const handleSave = ev => {
    if (products.length) {
      addProduct(products)
    }
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          首页
        </Link>
        <Link color="inherit" to="/products">
          产品列表
        </Link>
        <Typography color="textPrimary">导入产品</Typography>
      </Breadcrumbs>
      <div className={classes.content}>
        <Upload label="上传EXCEL表格" handleChange={handleUpload} id="import-upload"/>
        <div className={classes.content}>
          {
            products.length > 0
              ? <ProductsTable products={products}/>
              : <Paper className={classes.root}>
                  <Typography color="textPrimary">尚未导入任何产品</Typography>
                </Paper>
          }
          <Button
            variant="contained"
            color="primary"
            component="span"
            size="small"
            onClick={handleSave}
            className={classes.button}
            disabled={products.length === 0}
          >
            保存
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  
})

const mapDispatchToProps = dispatch => ({
  addProduct: products => dispatch(addProduct(products))
})

const connectedImport = connect(mapStateToProps, mapDispatchToProps)(Import)

export default connectedImport
