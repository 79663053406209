import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import moment from 'moment';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@material-ui/core';

import { deleteItem } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    minWidth: 220,
    display: 'flex',
    alignItems: 'center'
  },
  fixedLayout: {
    // tableLayout: 'fixed'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cellContent: {
    width: '100%'
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  warnButton: {
    color: 'rgb(220, 0, 78)'
  },
  alertButton: {
    color: 'red'
  },
  inputWidth: {
    width: 92,
  },
  input: {
    width: 92,
    paddingLeft: 6,
    border: '1px solid rgba(0, 0, 0, 0.23);'
  }
}));

const ProductsTable = props => {
  
  const classes = useStyles();
  const { className, caches, totalCount = 10, handleChange, deleteItem } = props;

  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    const { caches } = props;

    let selectedRows;

    if (event.target.checked) {
      selectedRows = caches.map(item => item._id);
    } else {
      selectedRows = [];
    }

    setSelectedRows(selectedRows);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelectedRows);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    handleChange({page})
  };

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    setRowsPerPage(pageSize);
    handleChange({pageSize})
  };

  const handleDelete = id => {
    deleteItem(id)
  }

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.fixedLayout}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.length === caches.length}
                        color="primary"
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < caches.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>保存时间</TableCell>
                    <TableCell>货品编码</TableCell>
                    <TableCell>产品</TableCell>
                    <TableCell>品牌</TableCell>
                    <TableCell>重量</TableCell>
                    <TableCell>尺寸</TableCell>
                    <TableCell>装箱量</TableCell>
                    <TableCell>
                      <Button disableElevation disableRipple>编辑</Button>
                    </TableCell>
                    <TableCell>
                      <Button disableElevation disableRipple>删除</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {caches.slice(0, rowsPerPage).map(item => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={item._id}
                      selected={selectedRows.indexOf(item._id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.indexOf(item._id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, item._id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>{moment(item.saveTime).format('YY/MM/DD hh:mm A')}</TableCell>
                      <TableCell>{item.modelId}</TableCell>
                      <TableCell>
                        <Typography variant="body1">{item.cnName}</Typography>
                      </TableCell>
                      <TableCell>
                        {item.brand}
                      </TableCell>
                      <TableCell>
                        {item.weight}g
                      </TableCell>
                      <TableCell>
                        {item.size}cm
                      </TableCell>
                      <TableCell>
                        { item.qtyPerCtn }
                      </TableCell>
                      <TableCell>
                        <Link to={`/products/${item._id}?from=drafts`}>
                          <Button color="secondary">编辑详情</Button>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Button disableElevation disableRipple onClick={ev => handleDelete(item._id)}>删除草稿</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  // loading: state.products.loading,
  caches: state.caches.caches,
})

const mapDispatchToProps = dispatch => ({
  deleteItem: id => dispatch(deleteItem(id))
})

const connectedProductsTable = connect(mapStateToProps, mapDispatchToProps)(ProductsTable)

connectedProductsTable.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func
};

export default connectedProductsTable
