import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx'
import {
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';

import { Container } from '..'
import { updateOrder } from 'store/actions'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 12
  },
  flex: {
    display: 'flex',
  },
  center: {
    alignItems: 'center',
  },
  row: {
    '& > p': {
      marginRight: 10
    }
  },
  inputRow: {
    alignItems: 'flex-end'
  }
}));


function OrderInfo (props) {

  const classes = useStyles();
  const { order, currencyList, updateOrder } = props
  const { currencyCode = 'USD' } = order.user || {}
  const { code: currencyMark, label: currencyName } = currencyList.find(cur => cur.value === currencyCode) || {}

  const handleChange = ev => {

    updateOrder({
      ...order,
      [ev.target.name] : ev.target.value
    })
  }

  return (
    <Container title="订单信息" subtitle={`以下信息会在客户端展示， 所有金额都是以${currencyName}为单位`}>
      <Grid container spacing={1} alignItems="stretch" className={classes.content}>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">商品金额: </Typography>
            <Typography variant="subtitle2">{currencyMark}{order.subtotal}</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">商品总数: </Typography>
            <Typography variant="subtitle2">{order.qty}</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">预估重量: </Typography>
            <Typography variant="subtitle2">{(order.weight/1000).toFixed(2)}kg</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">预估体积: </Typography>
            <Typography variant="subtitle2">{order.volumn}cm</Typography><sup>3</sup>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">物流方式: </Typography>
            <TextField
              placeholder="DHL"
              name="deliveryMethod"
              onChange={handleChange}
              required
              value={order.deliveryMethod || ''}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">实际重量: </Typography>
            <TextField
              name="realWeight"
              placeholder="kg"
              type="number"
              onChange={handleChange}
              value={order.realWeight}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">实际体积: </Typography>
            <TextField
              name="realVolumn"
              placeholder="cm3"
              type="number"
              onChange={handleChange}
              value={order.realVolumn || 0}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">运费({currencyName}): </Typography>
            <TextField
              name="freight"
              type="number"
              onChange={handleChange}
              value={order.freight}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">货运单号: </Typography>
            <TextField
              name="trackingNumber"
              onChange={handleChange}
              value={order.trackingNumber || ''}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">包裹数量: </Typography>
            <TextField
              name="packageNum"
              type="number"
              onChange={handleChange}
              value={order.packageNum || 0}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">其他费用: </Typography>
            <TextField
              name="otherFee"
              type="number"
              placeholder={currencyName}
              onChange={handleChange}
              value={order.otherFee}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">折扣(小数): </Typography>
            <TextField
              name="discount"
              placeholder="0.03"
              type="number"
              onChange={handleChange}
              value={order.discount || 0}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = state => ({
  currencyList: state.global.currencyList,
})

const mapDispatchToProps = dispatch => ({
  updateOrder: option => dispatch(updateOrder(option)),
})

const connectedOrderInfo = connect(mapStateToProps, mapDispatchToProps)(OrderInfo)

export default connectedOrderInfo
