import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Collapse, Paper, Button, } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 420
  },
  paper: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
}));

const PriceInput = props => {
  const { className, handleDelete, handleChange, visible, style, ...rest } = props;

  const classes = useStyles();

  return (
    <Collapse in={visible} className={classes.root}>
      <Paper
        {...rest}
        className={clsx(classes.paper, className)}
        style={style}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleDelete}
        >
          删除选中订单
        </Button>
      </Paper>
    </Collapse>
  );
};

PriceInput.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  handleDelete: PropTypes.func,
  style: PropTypes.object
};

export default PriceInput;
