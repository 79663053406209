export const globalConst = {

  SHOW_LOADING: 'SHOW_LOADING',
  CLOSE_LOADING: 'CLOSE_LOADING',
  // 广告
  GET_AD_LIST: 'GET_AD_LIST',
  GET_AD: 'GET_AD',
  CREATE_AD: 'CREATE_AD',
  UPDATE_AD: 'UPDATE_AD',
  PATCH_AD: 'PATCH_AD',
  // 消息
  GET_MESSAGE_LIST: 'GET_MESSAGE_LIST',
  GET_MESSAGE: 'GET_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  GET_UNREAD_MESSAGE_COUNT: 'GET_UNREAD_MESSAGE_COUNT',
  GET_CURRENCY_LIST: 'GET_CURRENCY_LIST',
  GET_PRODUCTS_WITH_TAGS: 'GET_PRODUCTS_WITH_TAGS',
}