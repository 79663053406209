import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import clsx from 'clsx';
import { Button, Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import { ProductTable, Upload, Download } from './components';
import { productsService } from 'store/services';
import { Container, statusClass } from '..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
    button: {
      marginTop: theme.spacing(1)
    },
    spacingLeft: {
      marginLeft: theme.spacing(1)
    },
    link: {
      marginTop: 10,
      marginLeft: 8,
      display: 'block',
      cursor: 'pointer',
    },
    note: {
      marginTop: theme.spacing(3),
      paddingLeft: 8
    },
  })
)

const toJson = wb => {
  let results = []
  wb.SheetNames.forEach(sheetName => {
    const roa = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { header : 1 });
    if(roa.length) {
      let [keys, ...rows] = roa
      rows = rows.filter(row => row.length > 0)
      results = rows.map(getRow)

      function getRow (data) {
        let row = {}
        keys.forEach((key, index) => {
          row[key] = data[index]
        })
        return row
      }
    }
  });
  return results
}

export default function OrderTable (props) {

  const classes = useStyles();
  const { userId, order, updateOrder, showDialog } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = ev => {
    setOpen(false);
  }

  const handleUpload = e => {

    e.stopPropagation(); e.preventDefault();

    const files = e.target.files, f = files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, {type: 'array'});

      const items = toJson(workbook);
      createOrder(items);
      
    };
    reader.readAsArrayBuffer(f);
  }

  const createOrder = items => {
    setLoading(true);
    const skuIds = [];
    const skuMap = new Map();
    items.forEach(item => {
      skuIds.push(item.skuId);
      skuMap.set(item.skuId.toLowerCase(), item.qty);
    })
    productsService.fetchProductsBySkuIds(skuIds, userId)
      .then(res => {
        if (res.status === 0) {
          console.log(skuMap);
          const productItems = res.data;
          const orderItems = productItems.map(item => {
            item.qty = parseInt(skuMap.get(item.skuId.toLowerCase()));
            return item;
          });
          setLoading(false);
          setOpen(false);
          updateOrder(orderItems);
        }
      })
  }

  return (
    <Container title="商品信息">
      <Button
        color="primary"
        variant="contained"
        onClick={ev => showDialog(true)}
        size="small"
        className={classes.button}
      >
        添加产品
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={clsx(classes.button, classes.spacingLeft)}
        onClick={ev => setOpen(true)}
      >
        导入产品
      </Button>
      <ProductTable order={order} updateOrder={updateOrder} className={classes.root} statusClass={statusClass}/>
      <Dialog onClose={handleClose} fullWidth={true} maxWidth="sm" open={open}>
        <DialogTitle id="simple-dialog-title">导入产品</DialogTitle>
        <DialogContent>
          {
            loading ? (
              <Typography variant="subtitle2" className={classes.padding}>正在处理数据，请耐心等待...</Typography>
            ) : (
              <Upload
                label="上传文件"
                handleChange={handleUpload}
                disabled={loading}
                id="import-upload"
              />
            )
          }
          <Download title="下载模板文件 >>" className={classes.link}/>
          <Typography variant="subtitle2" className={classes.note}>注意: skuId 和 qty 必须提供.</Typography>
        </DialogContent>
        
      </Dialog>
    </Container>
  )
}