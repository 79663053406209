import { quotaConst } from '../constants';
import { quotaService } from '../services';
import { alert } from './'

export function fetchQuota (userId, options) {

  return (dispatch, getState) => {

    const { auth, quota } = getState()
    if (auth.loggingIn || quota.loadingQuota) return

    dispatch(getRequest())

    return quotaService.fetchQuota(userId, options)
      .then(res => {
        if (res.status === 0) {
          dispatch(getSuccess(res.data))
          return res.data
        } else {
          return Promise.reject(res.message)
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(getFailure(err.message))
      })
  }

  function getRequest () {
    return {
      type: quotaConst.GET_QUOTA_REQUEST
    }
  }

  function getSuccess ({items, totalCount, user}) {
    return {
      type: quotaConst.GET_QUOTA_SUCCESS,
      items,
      user,
      totalCount
    }
  }

  function getFailure (error) {
    return {
      type: quotaConst.GET_QUOTA_FAILURE,
      error
    }
  }
}

export function patchQuota (userId, quotas) {

  return (dispatch, getState) => {

    const { quota } = getState()

    if (quota.patching) return

    dispatch(patchRequest())

    quotaService.patchQuota(userId, quotas)
      .then(res => {
        if (res.status === 0) {
          dispatch(alert({ type: 'success', message: '修改成功~' }))
          dispatch(patchSuccess())
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(alert({type: 'error', message: err.message}))
        dispatch(patchFailure(err.message))
      })

    function patchRequest () {
      return {
        type: quotaConst.PATCH_REQUEST
      }
    }

    function patchSuccess () {

      const { quota } = getState()
      const { items } = quota

      const update = item => {
        let index = items.findIndex(each => each.id === item.id);
        if (index > -1) {
          items[index] = Object.assign({}, items[index], item)
        }
      }

      Array.isArray(quotas) ? quotas.forEach(update) : update(quotas)

      return {
        type: quotaConst.PATCH_SUCCESS,
        items
      }
    }

    function patchFailure (error) {
      return {
        type: quotaConst.PATCH_FAILURE,
        error
      }
    }
  }
}

// 更新但不保存
export function muateQuotaUser (user) {

  return dispatch => {

    dispatch(mutateUser(user))
  }

  function mutateUser (user) {
    return {
      type: quotaConst.MUTATE_USER,
      user
    }
  }
}