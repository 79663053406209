import React, { useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  InputBase,
  TablePagination
} from '@material-ui/core';

import { getMarginRate } from 'helpers';
import { PriceInput } from './components'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fixedLayout: {
    tableLayout: 'fixed'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cellContent: {
    width: '100%'
  },
  input: {
    paddingLeft: 6,
    border: '1px solid rgba(0, 0, 0, 0.23);'
  }
}));

const defaultState = {
  editingRow: {
    id: 0,
    salesPrice: ''
  }
}

const QuotaTable = props => {

  const { className, items, totalCount, exchangeRate, currencyCode = 'USD', currencyList, handleChange, handleUpdate, } = props;
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editingRow, setEditingRow] = useState(defaultState.editingRow)
  const [mutatedRows, setMutatedRows] = useState([]);
  const [page, setPage] = useState(0);
  const {code: currencyMark, label: currencyCN } = currencyList.find(cur => cur.value === currencyCode) || {}

  const handleSelectAll = event => {
    const { items } = props;

    let selectedRows;

    if (event.target.checked) {
      selectedRows = items.map(item => item.id);
    } else {
      selectedRows = [];
    }

    setSelectedRows(selectedRows);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = newSelectedRows.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelectedRows);
  };

  const handleEditRow = payload => {
    if (!payload) return setEditingRow(defaultState.editingRow)
    setEditingRow({
      ...editingRow,
      ...payload
    })
  }

  const handleSave = id => {
    if (editingRow.id && editingRow.salesPrice > 0) {
      const targetRowIndex = items.findIndex(item => item.id === id);
      if (targetRowIndex > -1) {
        handleUpdate(editingRow)
      }
    }
    handleEditRow(null)
  }

  const handleMarginRateChange = marginRate => {
    if (marginRate > 0) {
      let rows = selectedRows.map(id => {
        let row = items.find(item => item.id === id)
        let salesPrice
        if (row) {
          salesPrice = ((row.costPrice * (1 + marginRate)) / exchangeRate).toFixed(2)
          return { id, salesPrice }
        }
        return null
      })
      setMutatedRows(rows.filter(row => !!row))
    }
  }

  const handleBulkUpdate = marginRate => {
    if (mutatedRows.length > 0) {
      handleUpdate(mutatedRows)
    }
    setSelectedRows([]);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
    handleChange({page})
  };

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    setRowsPerPage(pageSize);
    handleChange({pageSize})
  };

  const getMutatedItem = item => {
    let index = mutatedRows.findIndex(row => row.id === item.id)
    if (index > -1) return Object.assign(item, mutatedRows[index])
    return item
  }

  return (
    <div className={clsx(classes.root, className)}>
      <PriceInput
        visible={selectedRows.length > 0}
        handleSave={handleBulkUpdate}
        handleChange={handleMarginRateChange}
      />
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.fixedLayout}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.length === items.length}
                        color="primary"
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < items.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>货品编码</TableCell>
                    <TableCell>产品</TableCell>
                    <TableCell>规格</TableCell>
                    <TableCell>成本价</TableCell>
                    <TableCell>销售价</TableCell>
                    <TableCell>利润率</TableCell>
                    <TableCell>
                      <Button disableElevation disableRipple>编辑</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.slice(0, rowsPerPage).map(item => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={item.id}
                      selected={selectedRows.indexOf(item.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.indexOf(item.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, item.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>{item.skuId}</TableCell>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                            src={item.img + '?imageView2/1/w/40/h/40/ignore-error/1'}
                          />
                          <Typography variant="body1">{item.cnName}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{item.cnSpec}</TableCell>
                      <TableCell>￥{item.costPrice}</TableCell>
                      <TableCell>
                        <div className={classes.cellContent}>
                          {currencyMark}{
                            editingRow.id === item.id
                              ? <InputBase
                                  className={classes.input}
                                  value={editingRow.salesPrice}
                                  onChange={ev => handleEditRow({ salesPrice: ev.target.value })}
                                  placeholder={`${currencyCN}单位`}
                                />
                              : getMutatedItem(item).salesPrice
                          }
                        </div>
                      </TableCell>
                      <TableCell>
                        {getMarginRate(item.costPrice, getMutatedItem(item).salesPrice * exchangeRate)}
                      </TableCell>
                      <TableCell>
                        <div className={classes.cellContent}>
                          {
                            editingRow.id === item.id
                              ? (
                                <div>
                                  <Button color="primary" onClick={ev => handleSave(item.id)}>保存</Button>
                                  <Button color="secondary" onClick={ev => handleEditRow(null)}>取消</Button>
                                </div>
                              )
                              : <Button color="primary" onClick={ev => handleEditRow({id: item.id})}>编辑</Button>
                          }
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  currencyList: state.global.currencyList
})

const connectedQuotaTable = connect(mapStateToProps)(QuotaTable)

export default connectedQuotaTable;
