import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';

import { AccountProfile, AccountDetails } from './components';
import { productsService } from 'store/services'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = () => {
  const classes = useStyles();
  const user = {
    name: 'Shen Zhi',
    city: 'Los Angeles',
    country: 'USA',
    timezone: 'GTM-7',
    avatar: '/images/avatars/avatar_11.png'
  };
  
  const handleClick = () => {
    productsService.cleanData()
  }

  const handleDataClick = () => {
    productsService.cleanOrderData()
  }

  return (
    <div className={classes.root}>
      <Button onClick={handleClick}>整理数据</Button>
      <Button onClick={handleDataClick}>整理订单数据</Button>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AccountProfile user={user} />
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails />
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
