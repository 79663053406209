import { ordersConst } from '../constants';
import { alert } from './';
import { ordersService } from '../services';

// 请求订单列表
export function fetchOrders (options) {

  return (dispatch, getState) => {

    const { auth, orders } = getState()
    console.log(orders)
    if (auth.loggingIn || orders.loading) return

    dispatch(getOrdersRequest())
    dispatch(alert({type: 'info', message: '订单列表加载中..'}))

    ordersService.fetchOrders(options)
      .then(res => {
        if (res.status === 0) {
          dispatch(getOrdersSuccess(res.data))
          dispatch(alert({type: 'success', message: '订单加载成功..'}))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        console.error(err)
        dispatch(alert({ type: 'error', message: `订单加载失败, 原因: ${err.message}` }))
      })
    
  }

  function getOrdersRequest () {
    return {
      type: ordersConst.GET_ORDER_LIST_REQUEST
    }
  }

  function getOrdersSuccess ({ totalCount, orders }) {
    return {
      type: ordersConst.GET_ORDER_LIST,
      orders,
      totalCount
    }
  }
}

// 请求单个订单
export function fetchOrder (id) {

  return (dispatch, getState) => {

    
    const { auth, orders } = getState()
    if (auth.loggingIn || orders.loading) return
    
    dispatch(getOrdersRequest())
    dispatch(alert({type: 'info', message: '订单数据加载中...'}))

    ordersService.fetchOrder(id)
      .then(res => {
        if (res.status === 0) {
          const { financial, ...order } = res.data
          financial && dispatch(getFinancial(financial))
          dispatch(getOrderSuccess(order))
          dispatch(alert({type: 'success', message: '订单加载成功..'}))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        console.error(err)
        dispatch(alert({ type: 'error', message: `订单加载失败, 原因: ${err.message}` }))
      })
    
  }

  function getOrdersRequest () {
    return {
      type: ordersConst.GET_ORDER_LIST_REQUEST
    }
  }

  function getOrderSuccess (order) {
    return {
      type: ordersConst.GET_ORDER,
      order,
    }
  }

  function getFinancial (financial) {
    return {
      type: ordersConst.GET_FINANCIAL,
      financial
    }
  }

}

// 保存修改订单
export function confirmOrder (order) {

  return (dispatch, getState) => {

    const { orders } = getState()
    if (orders.patching) return

    dispatch(patchRequest())
    dispatch(alert({type: 'info', message: '正在提交请求，请勿操作..'}))

    ordersService.confirmOrder(order)
      .then(res => {
        if (res.status === 0) {
          dispatch(patchSuccess())
          dispatch(mutateOrder(order))
          dispatch(alert({type: 'success', message: '订单修改成功..'}))
        } else {
          return Promise.reject(new Error(res.message))
        }
      })
      .catch(err => {
        dispatch(patchFailure(err.message))
        dispatch(alert({ type: 'error', message: `订单修改失败, 原因: ${err.message}` }))
      })
  }

  function patchRequest () {
    return {
      type: ordersConst.PATCH_REQUEST,
    }
  }

  function patchSuccess () {
    return {
      type: ordersConst.PATCH_SUCCESS,
    }
  }

  function patchFailure (error) {
    return {
      type: ordersConst.PATCH_FAILURE,
      error
    }
  }
}

// 更新订单
export function updateOrder (option) {

  return dispatch => {

    dispatch(mutateOrder(option))
  }
}

// 报价表搜索产品
export function searchQuota (search) {

  return dispatch => {

    dispatch(handleSearch(search))
  }

  function handleSearch (search) {

    return {
      type: ordersConst.SEARCH_QUOTA,
      search
    }
  }
}

// 选择新产品
export function selectItems (items) {
  
  return dispatch => {

    dispatch(addOrderItem(items))
  }

  function addOrderItem (items) {

    return {
      type: ordersConst.ADD_NEW_ITEMS,
      items
    }
  }
}

// 添加新产品
export function addItemsToOrder (items) {

  return (dispatch, getState) => {

    const { orders } = getState()
    const { order } = orders
    let orderItemIds = order.items.map(item => item.skuId)
    let validItems = []
    let invalidItemNames = []

    items.forEach(each => {
      if (orderItemIds.indexOf(each.skuId) < 0) {
        validItems.push(transform(each))
      } else {
        invalidItemNames.push(each.skuId)
      }
    })

    if (invalidItemNames.length) {
      let message = invalidItemNames.join('，')
      dispatch(alert({ type: 'warn', message: `${message}在订单中已经存在,请直接修改数量!` }))
    }
    

    let length = validItems.length
    if (length > 0) {

      let _items = validItems.concat(order.items)
      dispatch(mutateOrder(Object.assign({}, order, { items: _items, dirtyItems: true, })))
    }
    setTimeout(() => {
      dispatch(alert({ type: 'info', message: `成功添加了 ${length} 个产品, 请记得保存订单修改` }))
    }, 2500);
  }

  function transform (item) {
    return {
      GUID: item.GUID,
      name: item.modelName,
      qty: item.qty,
      costPrice: item.costPrice,
      salesPrice: item.salesPrice,
      img: item.img,
      skuId: item.skuId,
      spec: item.spec
    }
  }

}

export function updateFinancial (payload = {}) {

  return (dispatch, getState) => {
    
    dispatch(update(payload))
  }

  function update (payload) {

    return {
      type: ordersConst.UPDATE_FINANCIAL,
      financial: payload
    }
  }
}

function calculateOrder (items) {

  let qty = 0;
  let subtotal = 0;
  let productsCost = 0;

  items.forEach(item => {
    qty += parseInt(item.qty)
    subtotal += item.qty * item.salesPrice
    productsCost += item.qty * item.costPrice
  })

  subtotal = parseFloat(subtotal.toFixed(2))
  productsCost = parseFloat(productsCost.toFixed(2))

  return {
    qty,
    subtotal,
    productsCost,
  }
}

function mutateOrder (order) {

  let { qty, subtotal } = calculateOrder(order.items)

  return {
    type: ordersConst.PATCH_ORDER,
    order: Object.assign({}, order, { qty, subtotal })
  }
}