import React from 'react';
import XLSX from 'xlsx';
import { connect } from 'react-redux';

import {
  Button,
} from '@material-ui/core';
import { alert } from 'store/actions'

function getFileName (order) {

  const date = new Date().toISOString().replace(/T.*/, '')
  const { id, user } = order
  return `${date}-${user.userName}-${id}.xlsx`
}

function getData (order) {

  let { items } = order
  let cols = ['货品编号', '品名', '规格', 'color', '单价', '数量', '金额', '重量', '单位',]

  let data = items.map(item => [
    item.skuId,
    item.name,
    item.cnSpec,
    item.spec,
    item.salesPrice,
    item.qty,
    (item.qty * item.salesPrice).toFixed(2),
    item.weight,
    '',
  ])

  return [cols, ...data]
}

function Download (props) {

  const { className, order, alert } = props

  const handleClick = ev => {
    
    alert({type: 'info', message: '正在生成表格，请勿离开...'})

    try {
      const fileName = getFileName(order)
      const data = getData(order)
      const wsName = 'SheetJS'
    
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(data)

      console.log('start writing to wb')
  
      XLSX.utils.book_append_sheet(wb, ws, wsName)
      console.log('start writing file')
      XLSX.writeFile(wb, fileName)
    } catch (err) {
      console.error(err)
      alert({type: 'error', message:`生成表格出错, 原因: ${err.message}`})
    }
  }

  return (
    <Button
      className={className}
      color="primary"
      variant="contained"
      size="small"
      onClick={handleClick}
    >
      下载订单
    </Button>
  )
}

const mapStateToProps = state => ({
  order: state.orders.order,
})

const mapDispatchToProps = dispatch => ({
  alert: payload => dispatch(alert(payload)),
})

const connectedDownload = connect(mapStateToProps, mapDispatchToProps)(Download)

export default connectedDownload