import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@material-ui/core';
import { confirm } from 'store/actions';
import { productsService } from 'store/services'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  fixedLayout: {
    // tableLayout: 'fixed'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tagContainer: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1)
    }
  }
}));

const ProductsTable = props => {
  
  const classes = useStyles();
  const { className, products, totalCount = 10, handleChange, confirm } = props;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleDeleteTag = (productId, tagId) => {
    confirm('确定从商品上移除该标签吗？')
      .then(() => {
        return productsService.removeTagFromProduct({productId, tagId})
      })
      .then(res => {
        console.log()
      })
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    handleChange({page})
  };

  const handleRowsPerPageChange = event => {
    const pageSize = event.target.value
    setRowsPerPage(pageSize);
    handleChange({pageSize})
  };

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.fixedLayout}>
                <TableHead>
                  <TableRow>
                    <TableCell>货品编码</TableCell>
                    <TableCell>产品</TableCell>
                    <TableCell>图片</TableCell>
                    <TableCell>标签</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(products).slice(0, rowsPerPage).map(item => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={item.id}
                    >
                      <TableCell>{item.modelId}</TableCell>
                      <TableCell>
                        <Typography variant="body1">{item.cnName}</Typography>
                      </TableCell>
                      <TableCell>
                        <img src={item.coverImage + '?imageView2/1/w/50/h/50/ignore-error/1'} alt=""/>
                      </TableCell>
                      <TableCell>
                        <div className={classes.tagContainer}>
                          {
                            (item.tags || []).map(tag => <Chip key={tag.id} label={tag.label} variant="outlined" onDelete={ev => handleDeleteTag(item.id, tag.id)}/>)
                          }
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  // loading: state.products.loading,
  products: state.global.productsWithTags,
})

const mapDispatchToProps = dispatch => ({
  confirm: message => dispatch(confirm(message)),
})

const connectedProductsTable = connect(mapStateToProps, mapDispatchToProps)(ProductsTable)

connectedProductsTable.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func
};

export default connectedProductsTable
