import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
  Chip,
} from '@material-ui/core'

import { updateProduct, } from 'store/actions'
import { Tags } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  tag: {
    marginRight: theme.spacing(1)
  }
}));

const BasicForm = props => {

  const classes = useStyles();
  const { className, product, brands, categories } = props
  const { updateProduct } = props

  const handleChange = ev => {
    updateProduct({
      ...product,
      [ev.target.name]: ev.target.value
    })
  }

  const handleDelteTag = tag => {

    let index = product.tags.findIndex(t => t.id === tag.id)
    if (index < 0) return

    let newTags = []
    if (index === 0) {
      newTags = newTags.concat(product.tags.slice(1));
    } else if (index === product.tags.length - 1) {
      newTags = newTags.concat(product.tags.slice(0, -1));
    } else if (index > 0) {
      newTags = newTags.concat(
        product.tags.slice(0, index),
        product.tags.slice(index + 1)
      );
    }
    updateProduct({
      ...product,
      touchedTags: true,
      tags: newTags
    })
  }

  return (
    <div className={clsx(classes.root, className)}>
      <form>
        <Card
          className={classes.content}
        > 
          <CardHeader
            title={<Typography variant="h5" component="h2">基本信息</Typography>}
          />
          <Divider/>
          <CardContent>
            <Grid container spacing={3}>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="中文品名"
                  margin="dense"
                  name="cnName"
                  onChange={handleChange}
                  required
                  value={product.cnName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="英文品名"
                  margin="dense"
                  name="modelName"
                  onChange={handleChange}
                  required
                  value={product.modelName}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="工厂型号"
                  margin="dense"
                  name="modelId"
                  onChange={handleChange}
                  required
                  value={product.modelId}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="品牌"
                  margin="dense"
                  name="brand"
                  onChange={handleChange}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={product.brand}
                  variant="outlined"
                  inputProps={{
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                >
                  {brands.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="分类"
                  margin="dense"
                  name="category"
                  onChange={handleChange}
                  required
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={product.category}
                  variant="outlined"
                  inputProps={{
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                >
                  {categories.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="重量(g)"
                  margin="dense"
                  name="weight"
                  onChange={handleChange}
                  required
                  value={product.weight}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="尺寸(cm)"
                  margin="dense"
                  name="size"
                  onChange={handleChange}
                  required
                  value={product.size}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="装箱量"
                  margin="dense"
                  name="qtyPerCtn"
                  onChange={handleChange}
                  required
                  value={product.qtyPerCtn}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="发布日期"
                  margin="dense"
                  name="releaseDate"
                  placeholder="2021-06-22"
                  onChange={handleChange}
                  value={product.releaseDate}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="出货日期"
                  margin="dense"
                  name="availableDate"
                  placeholder="2021-06-22"
                  onChange={handleChange}
                  value={product.availableDate}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="建议零售价($)"
                  margin="dense"
                  name="MSRP"
                  onChange={handleChange}
                  value={product.MSRP}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div className={classes.flex}>
                  <Typography variant="subtitle2" className={classes.tag}>产品标签</Typography>
                  {
                    product.tags
                      ? product.tags.map(
                        tag => (
                        <Chip
                          className={classes.tag}
                          key={tag.id}
                          size="small"
                          variant="outlined"
                          label={tag.label}
                          onDelete={ev => handleDelteTag(tag)} 
                        />
                      )) : null
                  }
                  <Tags/>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </div>
  )
}

const mapStateToProps = state => ({
  // loading: state.products.loading,
  brands: state.brands.brands,
  categories: state.brands.categories,
  product: state.product.product,
})

const mapDispatchToProps = dispatch => ({
  updateProduct: product => dispatch(updateProduct(product)),
})

const connectedBasicForm = connect(mapStateToProps, mapDispatchToProps)(BasicForm)


connectedBasicForm.propTypes = {
  className: PropTypes.string,
  updateProduct: PropTypes.func
};

export default connectedBasicForm