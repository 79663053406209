import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Quota as QuotaView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Forbidden as ForbiddenView,
  Product as ProductView,
  Drafts as DraftsView,
  Orders as OrdersView,
  Order as OrderView,
  Verify as VerifyView,
  Ads as AdsView,
  Ad as AdView,
  Messages as MessagesView,
  Message as MessageView,
  Import as ImportView,
  Tags as TagsView,
  CreateOrder as CreateOrderView,
} from './views';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

export default props => ([
  {
    path: '/dashboard',
    component: DashboardView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/users',
    component: UserListView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/products',
    component: ProductListView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/drafts',
    component: DraftsView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/ads',
    component: AdsView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/ads/:id',
    component: AdView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/messages',
    component: MessagesView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/messages/:id',
    component: MessageView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/products/:id',
    component: ProductView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/products/new',
    component: ProductView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/import',
    component: ImportView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/orders',
    component: OrdersView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/user/:userId/orders',
    component: OrdersView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/orders/:id',
    component: OrderView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/createOrder/:userId',
    component: CreateOrderView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/account',
    component: AccountView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/settings',
    component: SettingsView,
    exact: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/typography',
    component: TypographyView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/icons',
    component: IconsView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized
    }
  },
  {
    path: '/quotation/:userId',
    component: QuotaView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized,
    }
  },
  {
    path: '/tags',
    component: TagsView,
    exact: true,
    protected: true,
    layout: MainLayout,
    props: {
      loggingIn: props.loggingIn,
      loggedIn: props.loggedIn,
      authorized: props.authorized,
    }
  },
  {
    path: '/sign-up',
    component: SignUpView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/sign-in',
    component: SignInView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/verify-email',
    component: VerifyView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/not-found',
    component: NotFoundView,
    exact: true,
    layout: MinimalLayout,
  },
  {
    path: '/forbidden',
    component: ForbiddenView,
    exact: true,
    layout: MinimalLayout,
  }
])