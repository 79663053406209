import { ordersConst } from '../constants';

const defaultState = {
  totalCount: 0,
  loading: false,
  patching: false,
  orders: [],
  quotaSearch: '',
  newItems: [], // 订单新增的商品
  financial: {
    orderId: '',
    productsCost: 0,
    shippingCost: 0,
    otherCost: 0,
    orderAmount: 0,
    shipping: 0,
    otherFee: 0,
    exchangeRate: 6.8,
    margin: 0,
    refund: 0,
  },
  order: {
    id: 0,
    trackStatus: 0,
    qty: 0,
    subtotal: 0,
    freight: 0,
    serviceCharge: 0,
    weight: 0,
    volumn: 0,
    discount: 0,
    realWeight: 0,
    receivedPayment: 0,
    packageNum: 0,
    otherFee: 0,
    hasPackingList: 0,
    deliveryMethod: '',
    trackingNumber: '',
    customerRemark: '',
    sellerRemark: '',
    stockConfirmDate: '',
    paymentDate: '',
    deliveryDate: '',
    receptionDate: '',
    paymentMethod: '',
    dirtyItems: false, // mark if any item have been changed
    address: {},
    items: [],
    user: {}
  },
  error: ''
}

export function orders (state = defaultState, action) {
  switch (action.type) {
    case ordersConst.GET_ORDER_LIST:
      return Object.assign({}, state, {
        loading: false,
        totalCount: action.totalCount,
        orders: action.orders
      })
    case ordersConst.GET_ORDER:
      return Object.assign({}, state, {
        loading: false,
        order: action.order
      });
    case ordersConst.GET_ORDER_LIST_REQUEST:
      return Object.assign({}, state, {
        loading: true
      });
    case ordersConst.PATCH_ORDER:
      return Object.assign({}, state, {
        order: action.order,
        financial: {
          ...state.financial,
          ...calculateFinancial(action.order, state.financial)
        }
      });
    case ordersConst.SEARCH_QUOTA:
      return Object.assign({}, state, {
        quotaSearch: action.search
      });
    case ordersConst.ADD_NEW_ITEMS:
      return Object.assign({}, state, {
        newItems: action.items
      })
    case ordersConst.GET_FINANCIAL:
      return Object.assign({}, state, {
        financial: action.financial,
      })
    case ordersConst.UPDATE_FINANCIAL:
      return Object.assign({}, state, {
        financial: {
          ...action.financial,
          ...calculateFinancial(state.order, action.financial)
        },
      })
    case ordersConst.PATCH_REQUEST:
      return Object.assign({}, state, {
        patching: true
      })
    case ordersConst.PATCH_SUCCESS:
      return Object.assign({}, state, {
        patching: false
      })
    case ordersConst.PATCH_FAILURE:
      return Object.assign({}, state, {
        patching: false,
        error: action.error
      })
    default:
      return state
  }
}

function calculateFinancial (order, financial) {

  const { subtotal, items, receivedPayment, discount = 0, } = order
  const { shippingCost, otherCost, exchangeRate } = financial

  let productsCost = items.reduce((cur, next) => cur + (next.costPrice || 0) * next.qty, 0)
  let orderAmount = subtotal * (1 - discount)
  let margin = receivedPayment * exchangeRate - productsCost - shippingCost - otherCost

  orderAmount = parseFloat(orderAmount.toFixed(2))
  productsCost = parseFloat(productsCost.toFixed(2))
  margin = parseFloat(margin.toFixed(2))

  return {
    orderAmount,
    productsCost,
    margin
  }
}