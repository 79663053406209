export const productsConst = {

  // 获取产品列表
  GET_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_FAILURE: 'GET_PRODUCTS_FAILURE',

  // 获取单个产品
  GET_PRODUCT: 'GET_PRODUCT_REQUEST',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',

  // 获取产品模板
  GET_DEFAULT_PRODUCT: 'GET_DEFAULT_PRODUCT',

  // 新增产品
  ADD_REQUEST: 'ADD_PRODUCT_REQUEST',
  ADD_SUCCESS: 'ADD_PRODUCT_SUCCESS',
  ADD_FAILURE: 'ADD_PRODUCT_FAILURE',

  // 修改产品
  PATCH_REQUEST: 'PATCH_PRODUCT_REQUEST',
  PATCH_SUCCESS: 'PATCH_PRODUCT_SUCCESS',
  PATCH_FAILURE: 'PATCH_PRODUCT_FAILURE',

  // 更新产品
  MUTATE_PRODUCT: 'MUTATE_PRODUCT',
  UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',

  // 删除产品
  DELETE_PRODUCT: 'DELETE_PRODUCT',

  // 上传主图
  UPLOADING_PACKAGE: 'UPLOADING_PACKAGE',

  // 获取标签
  GET_TAGS: 'GET_TAGS',
  SAVE_TAG: 'SAVE_TAG',

  // 获取特定标签的产品列表
  GET_PRODUCTS_BY_TAGS: 'GET_PRODUCTS_BY_TAGS'
}