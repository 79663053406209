import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import { ProductsToolbar, ProductsTable } from './components';
import { fetchProducts, getBrands } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const defaultState = {
  options: {
    pageIndex: 1,
    pageSize: 50,
    search: '',
    brand: ''
  }
}

const Products = (props) => {
  const classes = useStyles();

  const [options, setOptions] = useState(defaultState.options)
  const { brands, fetchProducts, getBrands, } = props

  const handlePageChange = ({page, pageSize = options.pageSize}) => {
    setOptions({
      ...options,
      pageIndex: page,
      pageSize
    })
  }

  const handleSearch = search => {
    setOptions({
      ...options,
      brand: '',
      pageIndex: 1,
      search,
    })
  }

  const handleChange = ev => {
    setOptions({
      ...options,
      search: '',
      [ev.target.name]: ev.target.value,
      pageIndex: 1,
    })
  }

  useEffect(() => {
    fetchProducts(options)
  }, [options, fetchProducts])
  
  useEffect(() => {
    getBrands()
  }, [getBrands])

  return (
    <div className={classes.root}>
      <ProductsToolbar
        onSearch={handleSearch}
        brands={brands}
        search={options.search}
        brand={options.brand}
        handleChange={handleChange}
      />
      <div className={classes.content}>
        <ProductsTable handleChange={handlePageChange} options={options}/>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  // loading: state.products.loading,
  brands: state.brands.brands,
  // products: state.products.products,
  // totalCount: state.products.totalCount
})

const mapDispatchToProps = dispatch => ({
  fetchProducts: options => dispatch(fetchProducts(options)),
  getBrands: () => dispatch(getBrands()),
})

const connectedProducts = connect(mapStateToProps, mapDispatchToProps)(Products)

export default connectedProducts
