import request from 'common/request'

export const brandsService = {
  fetchBrands,
}

function fetchBrands () {
  return request.get({
    url: '/v2/brandList',
  })
}