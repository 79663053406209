import { userConst } from '../constants';

export function users (state = { loading: false, users: [], totalCount: 0, error: '' }, action) {
  switch (action.type) {
    case userConst.GET_REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case userConst.GET_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        totalCount: action.totalCount,
        users: action.users
      });
    case userConst.GET_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    case userConst.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        )
      };
    case userConst.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConst.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    default:
      return state
  }
}