import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx'
import {
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';

import { Container, statusClass } from '..'
import { updateFinancial, updateOrder } from 'store/actions'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 12
  },
  flex: {
    display: 'flex',
  },
  center: {
    alignItems: 'center',
  },
  row: {
    '& > p': {
      marginRight: 10
    },
    marginBottom: 6
  },
  inputRow: {
    alignItems: 'flex-end'
  }
}));


function Financial (props) {

  const classes = useStyles();
  const { order, financial, updateFinancial, updateOrder } = props

  const totalCost = financial.productsCost + parseFloat(financial.shippingCost) + parseFloat(financial.otherCost)

  useEffect(() => {
    updateOrder(order)
  }, [])

  const handleFinancialChange = ev => {

    updateFinancial({
      ...financial,
      dirtyFinancial: true,
      receivedPayment: order.receivedPayment,
      [ev.target.name]: parseFloat(ev.target.value) || ''
    })
  }

  return (
    <Container title="成本利润">
      <Grid container spacing={1} alignItems="stretch" className={classes.content}>
        <Grid item xs={6}>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">货品成本(￥): </Typography>
            <Typography variant="subtitle2">￥{financial.productsCost.toFixed(2)}</Typography>
          </div>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">运费成本(￥): </Typography>
            <TextField
              name="shippingCost"
              onChange={handleFinancialChange}
              value={financial.shippingCost}
            />
          </div>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">其他成本(￥): </Typography>
            <TextField
              name="otherCost"
              onChange={handleFinancialChange}
              value={financial.otherCost}
            />
            <Typography variant="subtitle2">{order.otherCost}</Typography>
          </div>
          <div className={clsx(classes.row, classes.flex, classes.center)}>
            <Typography variant="body2">总&ensp;成&ensp;本(￥): </Typography>
            <Typography variant="subtitle2">{totalCost.toFixed(2)}</Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">订单利润: </Typography>
            <Typography variant="subtitle2">{financial.margin}</Typography>
          </div>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">退税金额: </Typography>
            <TextField
              name="refund"
              onChange={handleFinancialChange}
              value={financial.refund}
            />
          </div>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">结汇汇率: </Typography>
            <TextField
              name="exchangeRate"
              onChange={handleFinancialChange}
              value={financial.exchangeRate}
            />
          </div>
          <div className={clsx(classes.row, classes.flex, classes.inputRow)}>
            <Typography variant="body2">利润总计: </Typography>
            <Typography variant="subtitle2">￥{financial.margin + financial.refund}</Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  order: state.orders.order,
  financial: state.orders.financial,
  currencyList: state.global.currencyList
})

const mapDispatchToProps = dispatch => ({
  updateFinancial: payload => dispatch(updateFinancial(payload)),
})

const connectedFinancial = connect(mapStateToProps, mapDispatchToProps)(Financial)

export default connectedFinancial