import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Breadcrumbs,
  Typography,
} from '@material-ui/core';

import { fetchMessage, updateMessage } from 'store/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  content: {
    flex: 1,
    marginTop: theme.spacing(2),
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    flex: 1
  },
  inner: {
    flex: 1
  },
  spacingTop: {
    marginTop: theme.spacing(2)
  },
}));

const Message = props => {

  const { message, fetchMessage, updateMessage } = props
  const classes = useStyles();
  const { id } = useParams()

  const handleClick = ev => {
    updateMessage(id, {
      isReply: true
    })
  }

  useEffect(() => {
    fetchMessage(parseInt(id))
  }, [id, fetchMessage])

  useEffect(() => {
    updateMessage(id, {
      hasRead: true
    })
  }, [id, updateMessage])

  return (
    <div className={clsx(classes.flexColumn, classes.root)}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link color="inherit" to="/">
          首页
        </Link>
        <Link color="inherit" to="/messages">
          留言列表
        </Link>
        <Typography color="textPrimary">查看留言</Typography>
      </Breadcrumbs>
      <Card className={clsx(classes.flexColumn, classes.content)}>
        <CardHeader
          subheader={moment(message.createdAt).format('YY/MM/DD hh:mm A')}
          title={message.subject}
        />
        <Divider/>
        <CardContent className={clsx(classes.flexColumn, classes.body)}>
          <div className={classes.inner}>
            <Typography variant="h6">留言内容：</Typography>
            <Typography variant="subtitle2" className={classes.spacingTop}>{message.message}</Typography>
          </div>
          <div className={classes.footer}>
            <Typography variant="subtitle2">姓名：{message.fullname}</Typography>
            <Typography variant="subtitle2">邮箱：{message.email}</Typography>
          </div>
        </CardContent>
      </Card>
      <div className={classes.spacingTop}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClick}
        >
          标记为已回复
        </Button>
      </div>
    </div>
  );
};

Message.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  message: state.global.message,
})

const mapDispatchToProps = dispatch => ({
  fetchMessage: id => dispatch(fetchMessage(id)),
  updateMessage: (id, body) => dispatch(updateMessage(id, body))
})

const connectedMessage = connect(mapStateToProps, mapDispatchToProps)(Message)

export default connectedMessage
