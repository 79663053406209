import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2)
  },
  inner: {
    minWidth: 1200
  },
  nameContainer: {
    minWidth: 220,
    display: 'flex',
    alignItems: 'center'
  },
  fixedLayout: {
    // tableLayout: 'fixed'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  row: {
    display: 'flex',
    borderTop: '1px solid #d5d5d5',
    padding: theme.spacing(1, 0),
    '&:first-child': {
      border: 0
    },
    '& > *': {
      marginRight: theme.spacing(1),
    }
  },
  flexGrow1: {
    flex: 1
  },
}));

const ProductsTable = props => {
  
  const classes = useStyles();
  const { className, products } = props;

  return (
    <Paper className={clsx(classes.root, className)}>
      <Collapse in={products.length > 0}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table className={classes.fixedLayout}>
              <TableHead>
                <TableRow>
                  <TableCell>工厂编号</TableCell>
                  <TableCell>中文品名</TableCell>
                  <TableCell>英文品名</TableCell>
                  <TableCell>品牌</TableCell>
                  <TableCell>分类</TableCell>
                  <TableCell>尺寸</TableCell>
                  <TableCell>重量</TableCell>
                  <TableCell>装箱量</TableCell>
                  <TableCell colSpan={6}>
                    <div className={classes.row}>
                      <Typography variant="body1" className={classes.flexGrow1}>skuId</Typography>
                      <Typography variant="body1" className={classes.flexGrow1}>中文规格</Typography>
                      <Typography variant="body1" className={classes.flexGrow1}>英文规格</Typography>
                      <Typography variant="body1">出厂价</Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button disableElevation disableRipple>编辑</Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map(product => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={product.modelId}
                  >
                    <TableCell>{product.modelId}</TableCell>
                    <TableCell>
                      <Typography variant="body1">{product.cnName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{product.modelName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{product.brand}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{product.category}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{product.size}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{product.weight}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{product.qtyPerCtn}</Typography>
                    </TableCell>
                    <TableCell colSpan={6}>
                      {
                        product.items.map(item => (
                          <div className={classes.row} key={item.skuId}>
                            <Typography variant="body1" className={classes.flexGrow1}>{item.skuId}</Typography>
                            <Typography variant="body1" className={classes.flexGrow1}>{item.cnSpec}</Typography>
                            <Typography variant="body1" className={classes.flexGrow1}>{item.spec}</Typography>
                            <Typography variant="body1">{item.costPrice}</Typography>
                          </div>
                        ))
                      }
                    </TableCell>
                    <TableCell>
                    <IconButton className={classes.close}>
                      <CloseRounded/>
                    </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </Collapse>
    </Paper>
  );
};

export default ProductsTable
