import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import moment from 'moment';
import {
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import { fetchAds } from '../../../../store/actions'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    minWidth: 220,
    display: 'flex',
    alignItems: 'center'
  },
  fixedLayout: {
    // tableLayout: 'fixed'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  warnButton: {
    color: 'rgb(220, 0, 78)'
  },
  alertButton: {
    color: 'red'
  },
  inputWidth: {
    width: 92,
  },
  input: {
    width: 92,
    paddingLeft: 6,
    border: '1px solid rgba(0, 0, 0, 0.23);'
  }
}));

const ProductsTable = props => {
  
  const classes = useStyles();
  const { className, ads, fetchAds } = props;

  useEffect(() => {
    fetchAds()
  }, [fetchAds])

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.fixedLayout}>
                <TableHead>
                  <TableRow>
                    <TableCell>广告ID</TableCell>
                    <TableCell>创建时间</TableCell>
                    <TableCell>广告名称</TableCell>
                    <TableCell>图片</TableCell>
                    <TableCell>广告类型</TableCell>
                    <TableCell>标题</TableCell>
                    <TableCell>
                      <Button disableElevation disableRipple>编辑</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ads.map(item => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={item.id}
                    >
                      <TableCell>
                        <Typography >
                          {item.id}
                        </Typography>
                      </TableCell>
                      <TableCell>{moment(item.createdAt).format('YY/MM/DD hh:mm A')}</TableCell>
                      <TableCell>{item.adName}</TableCell>
                      <TableCell>
                        <img src={item.img + '?imageView2/1/w/40/h/40/ignore-error/1'} alt=""/>
                      </TableCell>
                      <TableCell>
                        {item.adType}
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.ellipsis}>
                          {item.title}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Link to={`/ads/${item.id}`}>
                          <Button color="secondary">编辑</Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  // loading: state.products.loading,
  ads: state.global.ads,
})

const mapDispatchToProps = dispatch => ({
  fetchAds: () => dispatch(fetchAds())
})

const connectedProductsTable = connect(mapStateToProps, mapDispatchToProps)(ProductsTable)

connectedProductsTable.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func
};

export default connectedProductsTable
