import { alert } from './'
import { uuid } from '../helpers'
import { cacheConst, productsConst } from '../constants';

// 存入缓存
export function saveCache (content) {

  if (!content._id) {
    content._id = uuid()
  }

  return (dispatch, getState) => {

    const { caches } = getState()
    let cache = caches.caches.find(item => item._id === content._id)

    if (cache) {

      Object.assign(cache, content)
    } else {

      caches.caches.unshift(content)
    }
    localStorage.setItem(cacheConst.PRODUCT_CACHE_KEY, JSON.stringify(caches.caches))

    dispatch(alert({type: 'success', message: '已成功存入草稿~'}))
    dispatch((success()))
  }
}

// 获取缓存列表
export function getCaches () {

  return dispatch => dispatch(getCacheList())
}

// 获取单调缓存
export function getCacheItem (id) {

  return (dispatch, getState) => {
    const { caches } = getState()
    const cachedProduct = caches.caches.find(item => item._id === id)

    dispatch(getItem(cachedProduct))
  }
}

// 删除缓存
export function deleteItem (id) {

  return dispatch => dispatch(deleteCacheItem(id))
}

function getItem (product) {
  return {
    type: productsConst.GET_PRODUCT_SUCCESS,
    product,
  }
}

function getCacheList () {
  return {
    type: cacheConst.GET_CACHE_LIST
  }
}

function deleteCacheItem (id) {
  console.log(id)
  return {
    type: cacheConst.DELETE_CACHE_ITEM,
    _id: id
  }
}

function success () {

  return {
    type: cacheConst.SAVE_TO_CACHE
  }
}