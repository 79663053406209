import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 10,
      background: '#fff',
      '& td, & th': {
        padding: '11px 0 11px 10px',
        border: '1px solid #e5e5e5',
        borderWidth: '0 1px 1px 0',
        '&:last-child': {
          borderRightWidth: 1,
        },
        '&:first-child': {
          borderLeftWidth: 1
        },
        verticalAlign: 'top'
      },
      '& th': {
        borderTop: '1px solid #e5e5e5',
      }
    },
    flex: {
      display: 'flex'
    },
    flexColumn: {
      flexDirection: 'column'
    },
    startEdge: {
      marginLeft: -theme.spacing(2)
    },
    flexGrow3: {
      flex: 3
    },
    flexGrow2: {
      flex: 2
    },
    flexGrow1: {
      flex: 1
    },
    mainCell: {
      width: '70%',
      fontSize: 13,
    },
    miniCell: {
      width: '2%'
    },
    cell: {
      width: '10%',
      fontSize: 13,
    },
    input: {
      width: 68
    },
    stressText: {
      color: '#333',
      paddingLeft: 4,
      fontWeight: 400
    },
    alignItemsCenter: {
      alignItems: 'center',
      verticalAlign: 'middle !important'
    },
    textCenter: {
      textAlign: 'center'
    },
    highlight: {
      fontWeight: 700,
      color: theme.palette.secondary.main
    },
  }),
)